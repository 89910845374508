import { AuthRedirect } from "auth/components/auth-redirect";
import { ProfileView } from "./views/profile";

export const HOME_PATH = "/";
export const SUPPORT_PATH = "/support";
export const BOOKING_PATH = "/booking";
export const BOOK_PATH = "/book";
export const BOOKING_AREAS_PATH = "areas";
export const BOOKING_LOCATION_PATH = "locations";
export const BOOKING_SLOTS_PATH = "slots";
export const BOOKING_DETAILS_PATH = "details";
export const BOOKING_REVIEW_PATH = "review";
export const BOOKING_UPCOMING_PATH = BOOKING_PATH + "/:id";
export const PROFILE_PATH = "/profile";

export const AREAS_PATH = "areas";
export const AREA_PATH = AREAS_PATH + "/:id";

export const LOCATIONS_PATH = "locations";
export const LOCATION_PATH = LOCATIONS_PATH + "/:id";

export const userRoutes = [
  {
    path: PROFILE_PATH,
    label: "Profile",
    element: (
      <AuthRedirect withAuth>
        <ProfileView />
      </AuthRedirect>
    ),
  },
];
