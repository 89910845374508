import { SideNav } from "admin/components/side-nav";
import { Router } from "common/components/router";

export const Entry: React.FC<React.PropsWithChildren> = () => {
  return (
    <div
      className="md:h-[100vh] flex flex-col md:flex-row"
      data-testid="main-app"
    >
      <div className="flex-1 md:max-w-[230px] md:bg-white">
        <SideNav />
      </div>
      <div className="flex-1 border-l p-6 bg-gray-50 overflow-y-auto">
        <Router />
      </div>
    </div>
  );
};
