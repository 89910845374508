import { addAdminStation, FETCH_ADMIN_STATIONS_QUERY } from "admin/endpoints";
import { Station } from "admin/types";
import { InputForward } from "common/components/input-forward";
import { SelectForward } from "common/components/select-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { ModalProps } from "common/types";
import { Button } from "design-system/button";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { DialogFooter, DialogHeader } from "shad/components/ui/dialog";

interface Props {
  locationId: string;
}

interface FormProps extends Omit<Station, "id" | "locationId"> {
  locationId: string;
}

const defaultValues: FormProps = {
  name: "",
  ip: "",
  port: "",
  locationId: "",
  type: "DEFAULT",
};

const getInitialFormData = (locationId: string) => {
  return { ...defaultValues, locationId };
};

export const StationModal: React.FC<ModalProps & Props> = ({
  handleClose,
  locationId,
}) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: getInitialFormData(locationId),
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => addAdminStation(data),
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([FETCH_ADMIN_STATIONS_QUERY, locationId]);
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutation.mutate(data);
  };

  return (
    <>
      <DialogHeader>Station</DialogHeader>
      <div className="space-y-4">
        <InputForward
          {...useNormalField({
            name: "name",
            register,
            errors,
            placeholder: "Name",
          })}
        />

        <InputForward
          {...useNormalField({
            name: "ip",
            register,
            errors,
            placeholder: "IP",
          })}
        />

        <SelectForward
          {...register("type", {
            required: true,
          })}
          placeholder="Type of station"
          options={[
            { label: "Chinese locks", value: "DEFAULT" },
            {
              label: "Metra locks",
              value: "METRA",
            },
            {
              label: "Manual (No locks)",
              value: "MANUAL",
            },
          ]}
          errors={errors}
        />

        <InputForward
          {...useNormalField({
            name: "port",
            register,
            errors,
            placeholder: "Port",
          })}
        />
      </div>
      <DialogFooter>
        <Button
          isLoading={mutation.isLoading}
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          Add
        </Button>
      </DialogFooter>
    </>
  );
};
