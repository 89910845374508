import { CircularProgress, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AsyncSelect, { AsyncProps } from "react-select/async";
interface Props extends AsyncProps<any, any, any> {
  initialValue?: string | null;
  fetchInitialValue?: (
    value: string
  ) => Promise<{ label: string; value: string }>;
}
export const SAutocomplete: React.FC<Props> = ({
  initialValue,
  fetchInitialValue,
  ...props
}) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);
  const [loading, setIsLoading] = useState(!!initialValue);

  useEffect(() => {
    if (initialValue && fetchInitialValue) {
      (async () => {
        setIsLoading(true);
        if (fetchInitialValue) {
          const def = await fetchInitialValue(initialValue);

          setDefaultValue(def);
          setIsLoading(false);
        }
      })();
    }
  }, [initialValue, fetchInitialValue]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Stack>
      <AsyncSelect
        menuPlacement="top"
        defaultValue={defaultValue}
        cacheOptions
        {...props}
      />
    </Stack>
  );
};
