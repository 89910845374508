import {
  addAdminLocation,
  FETCH_ADMIN_AREAS_LOCATION_QUERY,
} from "admin/endpoints";
import { useUtils } from "admin/hooks/use-utils";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { Location, ModalProps } from "common/types";
import { Button } from "design-system/button";
import { Checkbox } from "design-system/checkbox";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "shad/components/ui/dialog";

interface Props {
  areaId: string;
}

type FormWeekDays = {
  id: string;
  value: string;
  active: boolean;
}[];

interface FormProps extends Omit<Location, "id" | "weekDays"> {
  weekDays: FormWeekDays;
}

const weekDaysDefault: FormWeekDays = [
  { id: "1", value: "1", active: true },
  { id: "2", value: "2", active: true },
  { id: "3", value: "3", active: true },
  { id: "4", value: "4", active: true },
  { id: "5", value: "5", active: true },
  { id: "6", value: "6", active: true },
  { id: "0", value: "0", active: true },
];

const defaultValues: FormProps = {
  name: "",
  areaId: "",
  street: "",
  postcode: "",
  openingTime: "07:30",
  closingTime: "18:30",
  isActive: true,
  weekDays: weekDaysDefault,
};

const getInitialFormData = (areaId: string) => {
  return { ...defaultValues, areaId };
};

export const LocationModal: React.FC<ModalProps & Props> = ({
  handleClose,
  areaId,
}) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const { shortWeekDays } = useUtils();
  const {
    handleSubmit,
    register,
    control,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: getInitialFormData(areaId),
  });

  const { fields, update } = useFieldArray({
    control,
    name: "weekDays",
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => addAdminLocation(data),
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([
          FETCH_ADMIN_AREAS_LOCATION_QUERY,
          areaId,
        ]);
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutation.mutate({
      ...data,
      weekDays: data.weekDays.reduce((acc, curr) => {
        if (curr.active) {
          acc.push(curr.value);
        }

        return acc;
      }, [] as string[]),
    });
  };

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Location</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 my-4">
        <InputForward
          {...useNormalField({
            name: "name",
            register,
            errors,
            placeholder: "Name",
          })}
        />

        <InputForward
          {...useNormalField({
            name: "street",
            register,
            errors,
            placeholder: "Street",
          })}
        />

        <InputForward
          {...useNormalField({
            name: "postcode",
            register,
            errors,
            placeholder: "Postcode",
          })}
        />

        <div className="bg-gray-100 space-y-4 p-2 rounded-md">
          <CheckboxForward
            errors={errors}
            label="Is active"
            {...register("isActive")}
          />

          <div className="flex space-x-4 text-sm">
            {fields.map((field, index) => {
              return (
                <Controller
                  key={field.id}
                  control={control}
                  name={`weekDays`}
                  render={() => (
                    <Checkbox
                      label={shortWeekDays[parseInt(field.value)]}
                      onChange={() => {
                        update(index, {
                          ...field,
                          active: !field.active,
                        });
                      }}
                      isChecked={field.active}
                    />
                  )}
                />
              );
            })}
          </div>
          <div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InputForward
                  type="time"
                  step={300}
                  {...useNormalField({
                    name: "openingTime",
                    register,
                    errors,
                    placeholder: "Opening time",
                  })}
                />
              </div>
              <div>
                <InputForward
                  type="time"
                  step={300}
                  {...useNormalField({
                    name: "closingTime",
                    register,
                    errors,
                    placeholder: "Closing time",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button
          isLoading={mutation.isLoading}
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          Add
        </Button>
      </DialogFooter>
    </div>
  );
};
