import { Fonts } from "common/fonts";

export const Theme: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <>
      <Fonts />
      {props.children}
    </>
  );
};
