import { atom } from "recoil";
import { BookingState } from "./types";
import { MINIMUM_BOARDS_NR, MINIMUM_DURATION } from "./utils";

export const INITIAL_BOOKING_SESSION: BookingStateAtom = {
  boardsNumber: MINIMUM_BOARDS_NR,
  duration: MINIMUM_DURATION,
  date: null,
  discount: "",
};

export type BookingStateAtom = Partial<BookingState>;

export const bookingState = atom<BookingStateAtom>({
  key: "bookingState",
  default: INITIAL_BOOKING_SESSION,
});

export const showSettingsState = atom<boolean>({
  key: "showSettingsState",
  default: false,
});
