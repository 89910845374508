import { Box, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  deleteAdminDiscount,
  fetchAdminDiscounts,
  FETCH_ADMIN_DISCOUNTS_QUERY,
  FETCH_ADMIN_DISCOUNT_USAGE_COUNT_QUERY,
  fetchAdminDiscountUsageCount,
} from "admin/endpoints";
import { DiscountModal } from "admin/modals/discount";
import { LOCATIONS_ADMIN_PATH, USERS_ADMIN_PATH } from "admin/routes";
import { modalState } from "common/atoms";
import { Discount } from "common/types";
import { SLink } from "design-system/s-link";
import { STable } from "design-system/s-table";
import { Circle, DotsThree } from "phosphor-react";
import React from "react";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { Header } from "admin/components/header";
import { Button } from "design-system/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "shad/components/ui/dropdown-menu";
import { AxiosError } from "axios";
import { ConfirmationButton } from "common/components/confirmation-button";
import { queryClient } from "index";
import { useAuth } from "auth/hooks/use-auth";
import { toast } from "common/utils";

export const DiscountsView: React.FC = () => {
  const { isSuperadmin } = useAuth();
  const { data, isLoading } = useQuery([FETCH_ADMIN_DISCOUNTS_QUERY], () => {
    return fetchAdminDiscounts();
  });
  const setModal = useSetRecoilState(modalState);

  return (
    <div className="space-y-4">
      <Header title="Discounts">
        {isSuperadmin && (
          <Button
            className="self-end"
            onClick={() =>
              setModal({
                key: "DISCOUNT",
                component: DiscountModal,
                props: {},
              })
            }
          >
            Add
          </Button>
        )}
      </Header>
      <STable columns={columns} data={data} isLoading={isLoading} />
    </div>
  );
};

const columnHelper = createColumnHelper<any>();
const columns = [
  columnHelper.accessor("id", {
    cell: (info) => <Box>{info.getValue()}</Box>,
  }),
  columnHelper.display({
    id: "profile",
    header: () => <Box as="strong">Profile</Box>,
    cell: (info) => {
      const profile = info.row.original.profile;

      if (!profile) {
        return <Box>--</Box>;
      }

      return (
        <SLink
          to={`${USERS_ADMIN_PATH}/${profile?.id}`}
          textDecoration="underline"
        >
          {profile?.firstName} {profile?.lastName}
        </SLink>
      );
    },
  }),
  columnHelper.display({
    id: "location",
    header: () => <Box as="strong">Location</Box>,
    cell: (info) => {
      const location = info.row.original.location;

      if (!location) {
        return <Box>--</Box>;
      }

      return (
        <SLink
          textDecoration="underline"
          to={`/${LOCATIONS_ADMIN_PATH}/${location?.id}`}
        >
          {location?.name}
        </SLink>
      );
    },
  }),
  columnHelper.display({
    id: "area",
    header: () => <Box as="strong">Area</Box>,
    cell: (info) => {
      const area = info.row.original.area;

      if (!area) {
        return <Box>--</Box>;
      }

      return <Box>{area?.name}</Box>;
    },
  }),
  columnHelper.accessor("value", {
    header: () => <Box as="strong">Value</Box>,
    cell: (info) => <Box>{info.getValue() || "Free"}</Box>,
  }),
  columnHelper.accessor("code", {
    header: () => <Box as="strong">Code</Box>,
    cell: (info) => <Box>{info.getValue()}</Box>,
  }),
  columnHelper.accessor("code", {
    header: () => <Box as="strong">Usage count</Box>,
    cell: (info) => <DiscountUsageCount code={info.row.original.code} />,
  }),
  columnHelper.accessor("timeLimit", {
    header: () => <Box as="strong">Duration</Box>,
    cell: (info) => <Box>{info.getValue() || "--"}</Box>,
  }),
  columnHelper.accessor("boardsLimit", {
    header: () => <Box as="strong">Boards</Box>,
    cell: (info) => <Box>{info.getValue() || "--"}</Box>,
  }),
  columnHelper.accessor("isActive", {
    header: () => (
      <Box as="strong" display="block" textAlign="center">
        Active
      </Box>
    ),
    cell: (info) => (
      <Flex justifyContent="center" align="center">
        <Circle
          size={16}
          weight="fill"
          color={info.getValue() ? "#3cde55" : "#ffb100"}
        />
      </Flex>
    ),
  }),
  columnHelper.accessor(() => "actions", {
    id: "actions",
    header: () => <div />,
    cell: (info) => <RowActions discount={info.row.original} />,
  }),
];

const RowActions = ({ discount }: { discount: Discount }) => {
  const { isSuperadmin } = useAuth();
  const setModal = useSetRecoilState(modalState);
  const onEdit = (discount: Discount) => {
    setModal({
      key: "DISCOUNT",
      component: DiscountModal,
      props: {
        discount,
      },
    });

    return;
  };
  const onClick = async () => {
    try {
      await deleteAdminDiscount(discount.id);
      queryClient.invalidateQueries(FETCH_ADMIN_DISCOUNTS_QUERY);
      toast.success({ message: "Done" });
    } catch (e) {
      toast.error({ message: (e as AxiosError).response?.data as string });
      console.log(e);
    }
  };

  if (!isSuperadmin) {
    return null;
  }
  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {isSuperadmin && (
            <DropdownMenuItem onClick={() => onEdit(discount)}>
              Edit
            </DropdownMenuItem>
          )}

          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-default"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const DiscountUsageCount = ({ code }: { code: string }) => {
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_DISCOUNT_USAGE_COUNT_QUERY, code],
    () => fetchAdminDiscountUsageCount(code)
  );
  return isFetching ? "--" : data;
};
