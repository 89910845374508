import { addArea, FETCH_ADMIN_AREAS_QUERY } from "admin/endpoints";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { Area, ModalProps } from "common/types";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { DialogHeader, DialogFooter } from "shad/components/ui/dialog";
import { Button } from "shad/components/ui/button";
import { SelectForward } from "common/components/select-forward";

interface Prop {
  item?: Area;
}

interface FormProps extends Omit<Area, "id"> {}

const defaultValues: FormProps = {
  name: "",
  timezone: "",
};

export const AreaModal: React.FC<ModalProps & Prop> = ({ handleClose }) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues,
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => addArea(data),
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([FETCH_ADMIN_AREAS_QUERY]);
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutation.mutate(data);
  };

  return (
    <div>
      <DialogHeader>Area</DialogHeader>
      <div>
        <div className="my-2 space-y-4">
          <InputForward
            {...useNormalField({
              name: "name",
              errors,
              register,
              placeholder: "Area name",
            })}
          />

          <SelectForward
            {...register("timezone", {
              required: true,
            })}
            placeholder="Timezone"
            options={Intl.supportedValuesOf("timeZone").map((timezone) => ({
              label: timezone,
              value: timezone,
            }))}
            errors={errors}
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          {mutation.isLoading ? "..." : "Add"}
        </Button>
      </DialogFooter>
    </div>
  );
};
