import { HOME_ADMIN_PATH } from "admin/routes";
import { List, SignOut } from "phosphor-react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { getSideMenuItems } from "routes";
// import { showSettingsState } from "user/atoms";
import { Button } from "shad/components/ui/button";
import { Location, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "auth/hooks/use-auth";
import { ReactComponent as SupLogo } from "assets/logo.svg";
import { cx } from "@chakra-ui/utils";
import { Dialog, DialogContent, DialogHeader } from "shad/components/ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import {
  BreakPointHooks,
  breakpointsTailwind,
} from "@react-hooks-library/core";

const routeMatches = (path: string, location: Location) =>
  path === location.pathname ||
  (path.length !== 1 && location.pathname.includes(path));

const sideMenuState = atom({
  key: "sideMenuState",
  default: false,
});
const { isSmaller } = BreakPointHooks(breakpointsTailwind);
export const SideNav: React.FC<React.PropsWithChildren> = () => {
  const [sideMenuOpen, setMenuState] = useRecoilState(sideMenuState);
  const isMdDown = isSmaller("md");
  const menu = isMdDown ? (
    <Dialog open={sideMenuOpen} onOpenChange={setMenuState}>
      <DialogTrigger className="absolute top-4 right-4">
        <List size={24} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="text-lg font-bold">Menu</DialogHeader>
        <SideMenu />
      </DialogContent>
    </Dialog>
  ) : (
    <SideMenu />
  );

  return (
    <div className="py-6 px-3 flex flex-col space-between h-full relative">
      <NavLink to={HOME_ADMIN_PATH} className="flex justify-center">
        <SupLogo className="h-12 md:h-32" />
      </NavLink>
      {menu}
    </div>
  );
};

const SideMenu = () => {
  // const setShowSettings = useSetRecoilState(showSettingsState);
  const { t } = useTranslation("common");
  const { setToken, isSuperadmin } = useAuth();
  const location = useLocation();
  const setMenuState = useSetRecoilState(sideMenuState);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col justify-between flex-1">
        <div className="space-y-4 py-4">
          <h2 className="mb-2 mt-8 px-4 text-lg font-semibold tracking-tight hidden md:block">
            Menu
          </h2>
          <div className="space-y-1">
            {getSideMenuItems(isSuperadmin).map((route) => {
              if (route.hideFromMenu) {
                return null;
              }

              return (
                <Button
                  key={route.path}
                  variant="ghost"
                  asChild
                  className={cx(
                    "w-full justify-start",
                    routeMatches(route.path, location) && "bg-purple-100"
                  )}
                >
                  <NavLink
                    to={route.path}
                    end={route.end}
                    onClick={() => setMenuState(false)}
                  >
                    <div className="mr-2 h-4 w-4">{route.icon}</div>
                    {route.label}
                  </NavLink>
                </Button>
              );
            })}
            {/* <Button
              variant="ghost"
              className="w-full justify-start"
              onClick={() => setShowSettings(true)}
            >
              <div className="mr-2 h-4 w-4">
                <Gear size={18} />
              </div>
              Settings
            </Button> */}
          </div>
        </div>
        <Button
          variant="ghost"
          className="w-full justify-start"
          onClick={() => setToken(null)}
        >
          <div className="mr-2 h-4 w-4">
            <SignOut size={18} />
          </div>
          {t("action.signout")}
        </Button>
      </div>
    </div>
  );
};
