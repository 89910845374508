import { tokenState } from "auth/atoms";
import { DecodedToken } from "auth/types";
import { jwtDecode } from "jwt-decode";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { fetchProfile } from "user/endpoints";

export const useAuth = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const decoded: DecodedToken | null = token ? jwtDecode(token) : null;
  const isLoggedIn = !!token;
  const {
    data: profile,
    isLoading: isProfileLoading,
    isError,
  } = useQuery(
    ["FETCH_PROFILE"],
    () => {
      return fetchProfile(decoded?._id || "");
    },
    {
      enabled: isLoggedIn && !!decoded?._id,
      staleTime: Infinity,
    }
  );
  const isAdmin = decoded?.role === "ADMIN";
  const isSuperadmin = decoded?.role === "SUPERADMIN";

  return {
    isLoggedIn,
    isProfileLoading,
    profile,
    token,
    setToken,
    isError,
    isAdmin,
    isSuperadmin,
  };
};
