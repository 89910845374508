import { Spinner } from "phosphor-react";
import { Ref, forwardRef } from "react";
import { ButtonProps, Button as ShadButton } from "shad/components/ui/button";

export const Button = forwardRef(
  (
    { children, isLoading, ...props }: ButtonProps & { isLoading?: boolean },
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <ShadButton disabled={props.disabled || isLoading} {...props} ref={ref}>
        {isLoading ? (
          <div className="animate-spin h-4 w-4">
            <Spinner size={16} />
          </div>
        ) : (
          children
        )}
      </ShadButton>
    );
  }
);

Button.displayName = "Button";

export type { ButtonProps };
