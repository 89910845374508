import { Header } from "admin/components/header";
import {
  fetchAdminLocation,
  FETCH_ADMIN_LOCATION_QUERY,
  updateAdminLocation,
  FETCH_FITOGRAM_LOCATIONS_QUERY,
  fetchFitogramLocations,
  fetchFitogramEvents,
  FETCH_FITOGRAM_EVENTS_QUERY,
  CREATE_PUBLISH_FITOGRAM_DAY_EVENTS_QUERY,
  createAndPublishFitogramDayEvents,
  DELETE_FITOGRAM_EVENT_QUERY,
  deleteFitogramEvent,
  updateSeatsFitogramDayEvents,
  UPDATE_SEATS_FITOGRAM_DAY_EVENTS_QUERY,
  FETCH_FITOGRAM_BOOKINGS_QUERY,
  fetchFitogramBookings,
} from "admin/endpoints";
import { useUtils } from "admin/hooks/use-utils";
import { AREAS_ADMIN_PATH } from "admin/routes";
import { AdminLocation, FitogramBooking, FitogramEvent } from "admin/types";
import { AxiosError } from "axios";
import { modalState } from "common/atoms";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { SelectForward } from "common/components/select-forward";
import { useDebounce } from "common/hooks/use-debounce";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { Location } from "common/types";
import { toast } from "common/utils";
import { getUuidValidator, isUuid } from "common/validators";
import { add, format, getDay, isToday, sub } from "date-fns";
import { Button } from "design-system/button";
import { Checkbox } from "design-system/checkbox";
import { ChevronLeft, ChevronRight, RefreshCcw, Trash } from "lucide-react";
import { Spinner } from "phosphor-react";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  CardFooter,
} from "shad/components/ui/card";
import { DialogFooter, DialogHeader } from "shad/components/ui/dialog";

type FormWeekDays = {
  id: string;
  value: string;
  active: boolean;
}[];

interface FormProps extends Omit<Location, "id" | "weekDays"> {
  weekDays: FormWeekDays;
}

const weekDaysDefault: FormWeekDays = [
  { id: "1", value: "1", active: true },
  { id: "2", value: "2", active: true },
  { id: "3", value: "3", active: true },
  { id: "4", value: "4", active: true },
  { id: "5", value: "5", active: true },
  { id: "6", value: "6", active: true },
  { id: "0", value: "0", active: true },
];

const defaultValues: FormProps = {
  name: "",
  areaId: "",
  street: "",
  postcode: "",
  fitogramId: null,
  openingTime: "07:30",
  closingTime: "18:30",
  isActive: true,
  fitogramProviderId: "",
  weekDays: weekDaysDefault,
};

const getInitialFormData = (item: Location | undefined) => {
  if (!item) {
    return defaultValues;
  }

  const { weekDays, ...rest } = item;

  return {
    ...rest,
    weekDays: weekDaysDefault.map((weekItem) => {
      const copy = { ...weekItem };
      copy.active = weekDays.includes(weekItem.value);

      return copy;
    }),
  };
};

export const LocationEditForm: React.FC = () => {
  const { successMessage } = useSnack();
  const { shortWeekDays } = useUtils();
  const { id } = useParams();
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { isValid, isDirty, errors, dirtyFields },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues,
  });

  const isActiveChecked = watch("isActive");
  const providerId = watch("fitogramProviderId");
  const fitogramId = watch("fitogramId");
  const isProviderIdValid = isUuid(providerId);

  const { fields, update } = useFieldArray({
    control,
    name: "weekDays",
  });

  useEffect(() => {
    if (!providerId) {
      setValue("fitogramId", null);
    }
  }, [providerId, setValue]);

  const { data: location, refetch: refetchLocation } = useQuery(
    [FETCH_ADMIN_LOCATION_QUERY, id],
    () => {
      if (id) {
        return fetchAdminLocation(id);
      }

      return null;
    }
  );

  const hasOnefitIntegration = !!location?.company?.integrations?.find(
    (integration) =>
      integration.provider === "ONEFIT" && integration.enabled === true
  );

  const { data: fitogramLocations } = useQuery(
    [FETCH_FITOGRAM_LOCATIONS_QUERY, providerId],
    () => {
      if (providerId) {
        return fetchFitogramLocations(providerId);
      }
      return null;
    },
    {
      enabled: hasOnefitIntegration && isProviderIdValid,
    }
  );

  const { mutate: updateLocation, isLoading: isLoadingLocation } = useMutation<
    any,
    any,
    any,
    any
  >(
    async (data) =>
      updateAdminLocation(data.id, {
        ...data,
        fitogramId: data.fitogramId !== "" ? data.fitogramId : null,
      }),
    {
      onSuccess: () => {
        successMessage("Done");
        refetchLocation();
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    updateLocation({
      ...data,
      weekDays: data.weekDays.reduce((acc, curr) => {
        if (curr.active) {
          acc.push(curr.value);
        }

        return acc;
      }, [] as string[]),
    });
  };

  useEffect(() => {
    if (location) {
      reset(getInitialFormData(location));
    }
  }, [location, reset]);

  return (
    <div className="space-y-4">
      <Header
        title={location?.name}
        subtitle={
          <div className="flex gap-2 text-gray-500 text-sm">
            <NavLink className="underline" to={AREAS_ADMIN_PATH}>
              Areas
            </NavLink>
            <span>/</span>
            <NavLink
              className="underline"
              to={`${AREAS_ADMIN_PATH}/${location?.area?.id}`}
            >
              {location?.area?.name}
            </NavLink>
          </div>
        }
      />
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Update location</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <InputForward
              {...useNormalField({
                name: "name",
                register,
                errors,
                placeholder: "Name",
              })}
            />

            <InputForward
              {...useNormalField({
                name: "street",
                register,
                errors,
                placeholder: "Street",
              })}
            />

            <InputForward
              {...useNormalField({
                name: "postcode",
                register,
                errors,
                placeholder: "Postcode",
              })}
            />

            {hasOnefitIntegration && (
              <div className="space-y-4 bg-blue-50 p-4 rounded-md">
                <strong>Onefit settings</strong>

                <div>
                  <InputForward
                    {...register("fitogramProviderId", {
                      ...getUuidValidator(),
                    })}
                    errors={errors}
                    placeholder="Provider id"
                  />
                </div>
                {fitogramLocations && (
                  <SelectForward
                    {...register("fitogramId")}
                    label="Onefit location"
                    placeholder="Select location"
                    options={fitogramLocations.map((loc) => ({
                      label: loc.name,
                      value: loc.id,
                    }))}
                    errors={errors}
                  />
                )}

                {dirtyFields.fitogramId || dirtyFields.fitogramProviderId ? (
                  <div className="text-center py-6">
                    Fill in provider id and location and click on Update below
                    to see the calendar
                  </div>
                ) : (
                  <FitogramEventsCalendar
                    providerId={providerId}
                    locationId={fitogramId}
                    isLocationActive={!!location?.isActive}
                    weekAvailability={location?.weekDays || []}
                  />
                )}
              </div>
            )}

            <div className="space-y-4 bg-gray-100 p-4 rounded-md">
              <CheckboxForward
                errors={errors}
                label={"Is active"}
                {...register("isActive")}
                isChecked={isActiveChecked}
              />

              <div className="flex gap-5">
                {fields.map((field, index) => {
                  return (
                    <Controller
                      key={field.id}
                      control={control}
                      name={`weekDays.${index}.value`}
                      render={() => (
                        <Checkbox
                          label={shortWeekDays[parseInt(field.value)]}
                          onChange={() => {
                            update(index, {
                              ...field,
                              active: !field.active,
                            });
                          }}
                          isChecked={field.active}
                        />
                      )}
                    />
                  );
                })}
              </div>
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <InputForward
                    type="time"
                    step={300}
                    {...useNormalField({
                      name: "openingTime",
                      register,
                      errors,
                      placeholder: "Opening time",
                    })}
                  />

                  <InputForward
                    type="time"
                    step={300}
                    {...useNormalField({
                      name: "closingTime",
                      register,
                      errors,
                      placeholder: "Closing time",
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-end">
          <Button
            isLoading={isLoadingLocation}
            disabled={!(isValid && isDirty)}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

const FitogramEventsCalendar = ({
  locationId,
  providerId,
  weekAvailability,
  isLocationActive,
}: {
  locationId?: string | null;
  providerId?: string;
  isLocationActive: boolean;
  weekAvailability: AdminLocation["weekDays"];
}) => {
  const [currentDate, setDate] = useState(new Date());
  const startDate = format(currentDate, "yyyy-MM-dd");
  const today = isToday(currentDate);
  const startDateDebounced = useDebounce(startDate, 300);
  const dayOfWeek = getDay(currentDate);
  const isAvailable =
    weekAvailability.includes(`${dayOfWeek}`) && isLocationActive;
  const {
    data: events,
    isFetching: isFetchingEvents,
    refetch: refetchEvents,
  } = useQuery(
    [FETCH_FITOGRAM_EVENTS_QUERY, providerId, locationId, startDateDebounced],
    () => {
      if (providerId && locationId) {
        return fetchFitogramEvents({
          providerId,
          locationId,
          startDate: startDateDebounced,
        });
      }
    },
    {
      enabled: !!(providerId && locationId),
    }
  );
  const onError = (e: AxiosError) => {
    // toast.error(e);
  };

  const onSuccess = () => {
    toast.success("Done");
    refetchEvents();
  };

  const { mutate: createAndPublish, isLoading: isLoadingCreatePublish } =
    useMutation(
      CREATE_PUBLISH_FITOGRAM_DAY_EVENTS_QUERY,
      () => {
        if (providerId && locationId) {
          return createAndPublishFitogramDayEvents({
            providerId,
            locationId,
            startDate,
          });
        }

        return Promise.reject();
      },
      {
        onSuccess,
        onError,
      }
    );

  const { mutate: updateSeats, isLoading: isUpdatingSeats } = useMutation(
    UPDATE_SEATS_FITOGRAM_DAY_EVENTS_QUERY,
    () => {
      if (providerId && locationId) {
        return updateSeatsFitogramDayEvents({
          providerId,
          locationId,
          startDate,
        });
      }

      return Promise.reject();
    },
    {
      onSuccess,
      onError,
    }
  );

  const { mutate: deleteEvent, isLoading: isDeletingEvent } = useMutation(
    DELETE_FITOGRAM_EVENT_QUERY,
    (eventId: string) => {
      if (providerId) {
        return deleteFitogramEvent(eventId, providerId);
      }

      return Promise.reject();
    },
    {
      onSuccess,
      onError,
    }
  );

  const isDoingOperation =
    isFetchingEvents ||
    isLoadingCreatePublish ||
    isDeletingEvent ||
    isUpdatingSeats;

  if (!providerId || !locationId) return null;

  const onPrev = () => {
    setDate(sub(currentDate, { days: 1 }));
  };

  const onNext = () => {
    setDate(add(currentDate, { days: 1 }));
  };

  const onCreateAndPublish = () => {
    createAndPublish();
  };

  const onUpdateSeats = () => {
    updateSeats();
  };

  return (
    <div className="rounded-md border border-1 bg-blue-100 border-blue-200 p-4">
      <header className="flex mb-2 items-center">
        <div className="flex gap-2 items-center">
          <strong>Calendar</strong>{" "}
          <div className="w-4">
            {isDoingOperation && <Spinner className="animate animate-spin" />}
          </div>
        </div>
        <nav className="ml-5 flex gap-1 items-center text-sm flex-1 justify-between">
          <div className="flex items-center">
            <button
              disabled={isDoingOperation || today}
              className="bg-blue-400 text-white px-2 py-1 rounded-full disabled:bg-blue-200"
              onClick={onPrev}
            >
              <ChevronLeft size={16} />
            </button>
            <div className=" px-2 py-1 rounded-full">
              {format(currentDate, "EEE, do MMM")}
            </div>
            <button
              disabled={isDoingOperation}
              className="bg-blue-400 text-white px-2 py-1 rounded-full disabled:bg-blue-200"
              onClick={onNext}
            >
              <ChevronRight size={16} />
            </button>
          </div>
          <div className="flex items-center justify-end gap-1">
            <button
              disabled={isDoingOperation}
              className="bg-purple-300 text-white px-2 py-1 rounded-full disabled:opacity-5"
              onClick={() => {
                refetchEvents();
                toast.success("Events refreshed");
              }}
            >
              <RefreshCcw
                size={16}
                className={isDoingOperation ? "animate animate-spin" : ""}
              />
            </button>
            <button
              disabled={!isAvailable || isDoingOperation}
              className="bg-blue-400 text-white px-2 py-1 rounded-full disabled:bg-blue-200"
              onClick={onUpdateSeats}
            >
              Update seats
            </button>
            <button
              disabled={!isAvailable || isDoingOperation}
              className="bg-blue-400 text-white px-2 py-1 rounded-full disabled:bg-blue-200"
              onClick={onCreateAndPublish}
            >
              Create & Publish events
            </button>
          </div>
        </nav>
      </header>
      {!isAvailable && (
        <div className="p-2 rounded-sm bg-orange-100 my-2">
          The location is inactive on this day
        </div>
      )}
      {(!events || !events.length) && !isDoingOperation ? (
        <div>No events on this day</div>
      ) : (
        <div className="grid grid-cols-4 gap-2">
          {events &&
            events.map((event) => (
              <FitogramEventCard
                key={event.eventId}
                event={event}
                isLoading={isDoingOperation}
                onDelete={() => deleteEvent(event.eventId)}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const FitogramEventCard = ({
  event,
  isLoading,
  onDelete,
}: {
  event: FitogramEvent;
  isLoading: boolean;
  onDelete: () => void;
}) => {
  const { data: bookings, isFetching: isFetchingBookings } = useQuery(
    [FETCH_FITOGRAM_BOOKINGS_QUERY, event.providerId, event.eventId],
    () => {
      return fetchFitogramBookings({
        providerId: event.providerId,
        eventId: event.eventId,
        take: "1000",
      });
    }
  );

  const setModal = useSetRecoilState(modalState);

  return (
    <div
      key={event.eventId}
      className="flex items-center justify-between p-2 rounded-sm bg-white border border-gray-200 text-sm"
    >
      <div>
        <div>
          {event.name} @ {event.startTime}
        </div>
        <div className="flex items-center gap-1 text-xs">
          <div>{event.seats} seats</div>
          <div>|</div>
          <div className="flex items-center gap-1">
            {isFetchingBookings ? (
              <Spinner
                className="animate animate-spin text-gray-500"
                size={12}
              />
            ) : !!bookings?.length ? (
              <button
                className="text-blue-500 underline"
                onClick={() => {
                  setModal({
                    key: "FITOGRAM_BOOKINGS",
                    component: FitogramBookingsModal,
                    props: {
                      bookings,
                    },
                  });
                }}
              >
                {bookings?.length} bookings
              </button>
            ) : (
              `0 bookings`
            )}
          </div>
        </div>
      </div>
      <button
        disabled={isLoading}
        className="text-red-300 px-2 py-1 rounded-full disabled:text-red-100"
        onClick={onDelete}
      >
        <Trash size={12} />
      </button>
    </div>
  );
};

export const FitogramBookingsModal = ({
  bookings,
}: {
  bookings: FitogramBooking[];
}) => {
  const setModal = useSetRecoilState(modalState);
  return (
    <div>
      <DialogHeader>Bookings</DialogHeader>
      <div className="my-4">
        {bookings.map((booking) => {
          return (
            <div
              className="mb-2 last:mb-0 p-2 text-sm bg-blue-100 rounded-sm"
              key={booking.id}
            >
              <div>
                Name: {booking.firstName} {booking.lastName}
              </div>
              <div>CustomerId: {booking.customerId}</div>
              <div>Status: {booking.bookingStatus}</div>
            </div>
          );
        })}
      </div>
      <DialogFooter>
        <Button
          onClick={() =>
            setModal({
              key: null,
              component: null,
            })
          }
        >
          Ok
        </Button>
      </DialogFooter>
    </div>
  );
};
