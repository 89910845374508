import { modalState } from "common/atoms";
import { useRecoilState } from "recoil";
import {
  DialogOverlay,
  DialogContent,
  Dialog,
  DialogClose,
} from "shad/components/ui/dialog";

const empty = {};

export const Modals: React.FC<React.PropsWithChildren> = () => {
  const [modal, setModal] = useRecoilState(modalState);

  const handleClose = () => {
    setModal({
      ...modal,
      key: null,
    });
  };

  const modalProps = {
    handleClose,
    ...(modal?.props ? modal.props : empty),
  };

  return (
    <Dialog onOpenChange={handleClose} open={!!modal?.key}>
      <DialogOverlay />
      <DialogClose />
      <DialogContent>
        {modal.component && <modal.component {...modalProps} />}
      </DialogContent>
    </Dialog>
  );
};
