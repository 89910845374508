import clsx from "clsx";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const Badge = ({
  to,
  children,
  variant = "default",
  className,
}: {
  to?: string;
  children: ReactNode;
  variant?: "default" | "success" | "danger" | "warning" | "outline";
  className?: string;
}) => {
  const child = to ? (
    <NavLink className="underline" to={to}>
      {children}
    </NavLink>
  ) : (
    children
  );
  const classes = clsx("py-1 px-2 inline-block text-sm rounded-sm", {
    "bg-gray-100": variant === "default",
    "bg-green-500 text-white": variant === "success",
    "bg-red-500 text-white": variant === "danger",
    "bg-orange-500 text-white": variant === "warning",
    "ring-1 ring-gray-300": variant === "outline",
  });

  return <div className={twMerge(classes, className)}>{child}</div>;
};
