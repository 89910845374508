import { Divider, Stack } from "@chakra-ui/react";
import { supxios } from "api";
import { FORGOT_PASSWORD_PATH } from "../../routes";
import { useSnack } from "common/hooks/use-snack";
import { getEmailValidator, getRequiredValidator } from "common/validators";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useAuth } from "auth/hooks/use-auth";
import { InputForward } from "common/components/input-forward";
import { SLink } from "design-system/s-link";
import { TOKEN_STORAGE, setInStorage } from "common/utils";
import { Button } from "design-system/button";

interface FormProps {
  email: string;
  password: string;
}

export const SignInView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const { setToken } = useAuth();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate: onSubmit, isLoading } = useMutation<any, any, FormProps, any>(
    async (data) => {
      const resp = await supxios.post("auth/signin", data);

      return resp.data;
    },
    {
      onSuccess: (resp) => {
        setInStorage(TOKEN_STORAGE, resp.token);
        setToken(resp.token);
        successMessage(t("snack.signin"));
      },
    }
  );

  return (
    <>
      <div className="container relative h-dvh flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0 bg-white">
        <div className="relative hidden h-dvh flex-col bg-muted p-10 text-white flex lg:flex">
          <div
            className="absolute inset-0 bg-zinc-900"
            style={{ backgroundImage: "url(/home.png)" }}
          />
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight text-black">
                {t("pageTitle.signIn")}
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email and password below to sign in
              </p>
            </div>
            <form className="space-y-4">
              <InputForward
                placeholder={t("placeholder.email")}
                errors={errors}
                type="email"
                {...register("email", { ...getEmailValidator() })}
              />

              <InputForward
                placeholder={t("placeholder.password")}
                label={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <div>{t("placeholder.password")} </div>
                    <SLink textDecor="underline" to={FORGOT_PASSWORD_PATH}>
                      {t("action.reset")}
                    </SLink>
                  </Stack>
                }
                errors={errors}
                type="password"
                {...register("password", { ...getRequiredValidator() })}
              />
              <div className="flex justify-end">
                <Button
                  type="submit"
                  onClick={handleSubmit((data) => onSubmit(data))}
                  disabled={!isValid || isLoading}
                  isLoading={isLoading}
                >
                  {t("pageTitle.signIn")}
                </Button>
              </div>
              <Divider />
            </form>
            <p className="px-8 text-center text-sm text-muted-foreground">
              By signing in, you agree to our{" "}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
