import { Navigate, useLocation } from "react-router-dom";
import { PROFILE_PATH } from "user/routes";
import { shouldUserCompleteProfile } from "user/utils";
import { useAuth } from "auth/hooks/use-auth";

interface Props extends React.PropsWithChildren {
  withAuth?: boolean;
  withoutAuth?: boolean;
}

export const AuthRedirect: React.FC<Props> = ({
  withAuth,
  withoutAuth,
  children,
}) => {
  const { profile, isLoggedIn } = useAuth();
  const location = useLocation();

  if (
    shouldUserCompleteProfile(profile) &&
    location.pathname !== PROFILE_PATH
  ) {
    return <Navigate to={PROFILE_PATH} />;
  }

  if (withAuth) {
    return !isLoggedIn ? <Navigate to="/" /> : <>{children}</>;
  }

  if (withoutAuth) {
    return isLoggedIn ? <Navigate to="/" /> : <>{children}</>;
  }

  return <>{children}</>;
};
