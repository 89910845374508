import { locationsAdminState, stationsAdminState } from "admin/atoms";
import { addDays, intlFormat, startOfWeek } from "date-fns";
import { localeState } from "i18n/atoms";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

export const days = [1, 2, 3, 4, 5, 6, 0];
const firstDOW = startOfWeek(new Date());

export const useUtils = () => {
  const locations = useRecoilValue(locationsAdminState);
  const stations = useRecoilValue(stationsAdminState);
  const locale = useRecoilValue(localeState);
  const getLocationByUid = useCallback(
    (id: string) => {
      return locations?.find((item) => item.id === id);
    },
    [locations]
  );
  const getStationByUid = useCallback(
    (id: string) => {
      return stations?.find((item) => item.id === id);
    },
    [stations]
  );

  const shortWeekDays = useMemo(
    () =>
      days.map((_, i) =>
        intlFormat(addDays(firstDOW, i), { weekday: "short" }, { locale })
      ),
    [locale]
  );

  return { locations, getLocationByUid, getStationByUid, shortWeekDays };
};
