import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "shad/components/ui/card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "shad/components/ui/table";
import { cn } from "shad/lib/utils";

interface Props {
  data: any[] | null | undefined;
  columns: ColumnDef<any, any>[];
  additionalRowProps?: Record<string, string>;
  isLoading: boolean;
  path?: string;
}
export const STable: React.FC<Props> = ({
  data,
  columns,
  additionalRowProps = {},
  isLoading,
  path = "",
}) => {
  const navigate = useNavigate();
  const { getHeaderGroups, getRowModel } = useReactTable({
    data: data || [],
    columns,
    state: {
      columnVisibility: {
        id: false,
      },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return <>Loading</>;
  }

  const hasNoData = !isLoading && (!data || !data?.length);

  return (
    <Card>
      <CardContent className="p-0">
        <Table className="overflow-hidden">
          <TableHeader>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {flexRender(header.column.columnDef.header, {
                      ...header.getContext(),
                    })}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {hasNoData ? (
              <TableRow>
                <TableCell colSpan={100} className="text-center text-gray-400">
                  No data
                </TableCell>
              </TableRow>
            ) : (
              getRowModel().rows.map((row) => (
                <TableRow
                  className={cn(
                    row.index % 2 ? "bg-gray-50" : "",
                    "hover:bg-gray-100 last:rounded-b-md"
                  )}
                  onClick={!!path ? () => navigate(path) : undefined}
                  key={row.id}
                  {...additionalRowProps}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
