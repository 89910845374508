import { Card, CardBody, Stack, Text } from "@chakra-ui/react";
import { supxios } from "api";
import { useSnack } from "common/hooks/use-snack";
import { getEmailValidator } from "common/validators";
import { Button } from "design-system/button";
import { PageTitle } from "design-system/titles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { InputForward } from "common/components/input-forward";

interface FormProps {
  email: string;
}

export const ForgotPasswordView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const {
    mutate: onSubmit,
    isLoading,
    isSuccess,
  } = useMutation<any, any, FormProps, any>(
    async (data) => {
      const resp = await supxios.post("password/forgot", data);

      return resp.data;
    },
    {
      onSuccess: () => {
        successMessage(t("snack.forgot"));
      },
    }
  );

  return (
    <Stack>
      <PageTitle>{t("pageTitle.forgotPassword")}</PageTitle>
      <Card>
        <CardBody>
          <Stack spacing={4}>
            {isSuccess ? (
              <Text>{t("check_email_to_reset_pwd")}</Text>
            ) : (
              <>
                <InputForward
                  placeholder={t("placeholder.email")}
                  errors={errors}
                  type="email"
                  {...register("email", { ...getEmailValidator() })}
                />

                <Button
                  onClick={handleSubmit((data) => onSubmit(data))}
                  disabled={!isValid}
                  isLoading={isLoading}
                >
                  {t("send_email_link")}
                </Button>
              </>
            )}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};
