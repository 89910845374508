import { ReactNode } from "react";

export const Header = ({
  title,
  subtitle,
  children,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div className="flex justify-between">
      <div className="space-y-1">
        <h2 className="text-2xl font-bold tracking-tight">{title}</h2>
        {subtitle}
      </div>
      {children}
    </div>
  );
};
