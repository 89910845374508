import { Box } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginationControlled } from "admin/components/pagination-controlled";
import {
  deleteAdminBooking,
  fetchAdminBookings,
  FETCH_ADMIN_BOOKINGS_QUERY,
  resendAdminBookingInvoice,
} from "admin/endpoints";
import {
  BOOKINGS_ADMIN_PATH,
  LOCATIONS_ADMIN_PATH,
  STATIONS_ADMIN_PATH,
  USERS_ADMIN_PATH,
} from "admin/routes";
import { BookingPopulated } from "admin/types";
import { useDebounce } from "common/hooks/use-debounce";
import { convertNumberToTime, delayQuery, toast } from "common/utils";
import { formatInTimeZone } from "date-fns-tz";
import { SLink } from "design-system/s-link";
import { STable } from "design-system/s-table";
import { DotsThree } from "phosphor-react";
import React, { Ref, forwardRef, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { TIMEZONE } from "user/utils";
import { getBadgeVariant } from "admin/utils";
import { Button } from "shad/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";
import { NavLink } from "react-router-dom";
import { Input } from "shad/components/ui/input";
import { Header } from "admin/components/header";
import { ConfirmationButton } from "common/components/confirmation-button";
import { Badge } from "design-system/badge";

const delayBookings = delayQuery<ReturnType<typeof fetchAdminBookings>>();

export const BookingsView: React.FC = () => {
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const query = useDebounce(q, 300);
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_BOOKINGS_QUERY, page, query],
    async () => {
      return delayBookings(() => fetchAdminBookings({ page, q: query }));
    }
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <div className="space-y-4">
      <Header title="Bookings">
        <Input
          value={q}
          onChange={(ev) => setQ(ev.target.value)}
          placeholder="Search..."
          className="w-[300px]"
        />
      </Header>
      <STable
        columns={bookingsColumns}
        data={data?.result}
        isLoading={isFetching}
      />
      {data && (
        <PaginationControlled
          currentPage={data?.currentPage}
          totalPages={data?.totalPages}
          onPageChange={setPage}
        />
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<BookingPopulated>();
export const bookingsColumns = [
  columnHelper.accessor("id", {
    cell: (info) => <Box>{info.getValue()}</Box>,
  }),
  columnHelper.accessor("profile", {
    header: () => <strong>Profile</strong>,
    cell: (info) => {
      const profile = info.getValue();

      return (
        <SLink
          to={`${USERS_ADMIN_PATH}/${profile?.id}`}
          textDecoration="underline"
        >
          {profile?.firstName} {profile?.lastName}
        </SLink>
      );
    },
  }),
  columnHelper.accessor("location", {
    header: () => <strong>Location</strong>,
    cell: (info) => {
      const location = info.getValue();

      return (
        <NavLink
          className="underline"
          to={`${LOCATIONS_ADMIN_PATH}/${location?.id}`}
        >
          {location?.name}
        </NavLink>
      );
    },
  }),
  columnHelper.accessor("boards", {
    header: () => <strong>Boards</strong>,
    cell: (info) => <Box>{info.row.original.boards}</Box>,
  }),
  columnHelper.accessor(() => "date", {
    id: "timestamp",
    header: () => <strong>Date</strong>,
    cell: (info) => (
      <Box>
        {formatInTimeZone(info.row.original.dateString, TIMEZONE, "dd MMM yy")}
      </Box>
    ),
  }),
  columnHelper.accessor(() => "times", {
    id: "times",
    header: () => <strong>Times</strong>,
    cell: (info) => (
      <Box>
        {convertNumberToTime(info.row.original.startHour)} -{" "}
        {convertNumberToTime(info.row.original.endHour)}
      </Box>
    ),
  }),
  columnHelper.accessor("lockers", {
    header: () => <strong>Lockers</strong>,
    cell: (info) => {
      const lockers = info.getValue() || [];
      return (
        <div className="space-y-1">
          {lockers.map((locker) => (
            <div key={locker.id}>
              <Badge
                className="text-xs"
                to={`${STATIONS_ADMIN_PATH}/${locker.stationId}`}
              >
                Locker: {locker.address}
              </Badge>
            </div>
          ))}
        </div>
      );
    },
  }),
  columnHelper.accessor("status", {
    header: () => <strong>Status</strong>,
    cell: (info) => (
      <Badge className="text-xs" variant={getBadgeVariant(info.getValue())}>
        {info.getValue()}
      </Badge>
    ),
  }),
  columnHelper.accessor("type", {
    header: () => <strong>Type</strong>,
    cell: (info) => {
      const type = info.getValue();
      return (
        <Badge
          className={`text-xs ${
            type === "USER" ? "bg-purple-400" : "bg-blue-400"
          } text-white`}
        >
          {type}
        </Badge>
      );
    },
  }),
  columnHelper.accessor(() => "createdAt", {
    id: "createdAt",
    header: () => <strong>Created at</strong>,
    cell: (info) => (
      <Box>
        {formatInTimeZone(
          (info.row.original as any).createdAt,
          TIMEZONE,
          "dd MMM - HH:mm"
        )}
      </Box>
    ),
  }),
  columnHelper.accessor(() => "actions", {
    id: "actions",
    header: () => <strong></strong>,
    cell: (info) => <BookingRowActions booking={info.row.original} />,
  }),
];

const BookingRowActions = ({ booking }: { booking: BookingPopulated }) => {
  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem asChild>
            <NavLink
              className="cursor-pointer"
              to={`${BOOKINGS_ADMIN_PATH}/${booking.id}`}
            >
              Details
            </NavLink>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Button
              variant="ghost"
              className="p-0 h-auto text-inherit font-normal"
              onClick={async () => {
                try {
                  await resendAdminBookingInvoice(booking);
                  toast.success({ message: "Done" });
                } catch {
                  toast.error({
                    message: "There was an error",
                  });
                }
              }}
            >
              Resend Invoice
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <DeleteWithAlert
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-pointer"
              id={booking.id}
              onDelete={deleteAdminBooking}
              invalidateKey={FETCH_ADMIN_BOOKINGS_QUERY}
            />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

interface DeleteWithAlertProps {
  id: string;
  invalidateKey?: string | any[];
  className?: string;
  onDelete: (id: string) => Promise<void>;
}
export const DeleteWithAlert: React.FC<DeleteWithAlertProps> = forwardRef(
  (props, ref: Ref<HTMLButtonElement>) => {
    const queryClient = useQueryClient();
    const onDelete = async () => {
      try {
        await props.onDelete(props.id);
        if (props.invalidateKey) {
          queryClient.invalidateQueries(props.invalidateKey);
        }
        toast.success({ message: "Deleted!" });
      } catch {
        toast.error({ message: "There was an error" });
      }
    };

    return (
      <ConfirmationButton
        size="icon"
        ref={ref}
        variant="ghost"
        className={props.className}
        onConfirm={onDelete}
      >
        Delete
      </ConfirmationButton>
    );
  }
);

DeleteWithAlert.displayName = "DeleteWithAlert";
