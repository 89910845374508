import { localeState } from "i18n/atoms";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { showSettingsState } from "user/atoms";

import { Dialog, DialogContent } from "shad/components/ui/dialog";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shad/components/ui/select";

export const SettingsDrawer: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const [locale, setLocale] = useRecoilState(localeState);
  const [openDrawer, setOpenDrawer] = useRecoilState(showSettingsState);

  return (
    <Dialog open={openDrawer} modal onOpenChange={setOpenDrawer}>
      <DialogContent className="sm:max-w-[425px]">
        <div className="space-y-4">
          <div className="space-y-1">
            <h3 className="text-sm">{t("placeholder.language")}</h3>
            <Select value={locale} onValueChange={(value) => setLocale(value)}>
              <SelectTrigger>
                <SelectValue placeholder={t("placeholder.language")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en">English</SelectItem>
                <SelectItem value="nl">Nederland</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
