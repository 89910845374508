import {
  FETCH_ADMIN_BOOKINGS_OVERVIEW_QUERY,
  FETCH_ADMIN_LOCATIONS_QUERY,
  fetchAdminBookingsOverview,
  fetchAdminLocations,
} from "admin/endpoints";
import {
  BOOKINGS_ADMIN_PATH,
  LOCATIONS_ADMIN_PATH,
  USERS_ADMIN_PATH,
} from "admin/routes";
import { BookingWithLocation } from "admin/types";
import { format } from "date-fns";
import { Button } from "design-system/button";
import { CalendarIcon } from "lucide-react";
import { useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Calendar } from "shad/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "shad/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shad/components/ui/select";
import { twMerge } from "tailwind-merge";

export const BookingByTimes = () => {
  const times = generateArrayWithHalfSteps(6, 23);
  const [date, setDate] = useState(new Date());
  const [locationId, setLocationId] = useState("ALL");
  const { data: locations = [] } = useQuery(
    [FETCH_ADMIN_LOCATIONS_QUERY],
    async () => {
      const result = await fetchAdminLocations({
        page: 1,
        limit: 50,
      });

      return result.result.map((loc) => ({ label: loc.name, value: loc.id }));
    }
  );
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_BOOKINGS_OVERVIEW_QUERY, date, locationId],
    async () => {
      const bookings = await fetchAdminBookingsOverview(
        format(date, "yyyy-MM-dd"),
        locationId === "ALL" ? undefined : locationId
      );
      return bookings || [];
    }
  );

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="flex md:flex-row flex-col justify-between">
        <h2 className="text-2xl font-bold tracking-tight mb-4">
          Active boards
        </h2>
        <div className="flex gap-2 md:flex-row flex-col">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant="outline"
                className={twMerge(
                  "max-w-[200px] w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />

                {format(date, "LLL dd, y")}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="single"
                defaultMonth={date}
                selected={date}
                onSelect={(s) => {
                  if (s) {
                    setDate(s);
                  }
                }}
                numberOfMonths={1}
              />
            </PopoverContent>
          </Popover>
          <Select
            value={locationId}
            onValueChange={(uuid) => setLocationId(uuid)}
          >
            <SelectTrigger className="max-w-[300px] w-full md:w-[250px] md:max-w-none">
              <SelectValue placeholder="Select location" />
            </SelectTrigger>
            <SelectContent>
              {[{ label: "All locations", value: "ALL" }, ...locations].map(
                (option, index: number) => (
                  <SelectItem key={`option-${index}`} value={option.value}>
                    {option.label}
                  </SelectItem>
                )
              )}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="space-y-4">
        {times.map(({ asNumber, asTime }) => {
          const bookings = data?.filter(
            (item) => item.startHour <= asNumber && item.endHour >= asNumber
          );
          return (
            <BookingByTimeSlot
              key={`time-${asTime}`}
              asTime={asTime}
              bookings={bookings}
            />
          );
        })}
      </div>
    </div>
  );
};

const BookingByTimeSlot = ({
  asTime,
  bookings,
}: {
  asTime: string;
  bookings: BookingWithLocation[] | undefined;
}) => {
  const totalBoards = bookings?.reduce((acc, curr) => {
    acc = acc + curr.boards;
    return acc;
  }, 0);
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      className={twMerge(
        "rounded-lg ",
        totalBoards && "bg-white shadow-sm hover:bg-purple-50"
      )}
    >
      <button
        className={twMerge(
          "rounded-lg w-full text-left cursor-default flex p-2",
          totalBoards && "cursor-pointer",
          isOpen && "bg-purple-100 rounded-b-none"
        )}
        onClick={() => {
          if (totalBoards) {
            setOpen(!isOpen);
          }
        }}
      >
        <div className="w-16">{asTime}</div>
        <div
          className={twMerge(
            "w-6 h-6 rounded-full ring-1 text-sm ring-purple-200 flex items-center justify-center",
            totalBoards && "bg-green-500 text-white"
          )}
        >
          {totalBoards}
        </div>
      </button>
      {!!bookings?.length && (
        <div
          className={twMerge(
            "space-y-2 rounded-xl mt-2 hidden",
            isOpen && "block"
          )}
        >
          <div className="flex px-2 text-sm">
            <div className="w-[90px]">ID</div>
            <div className="w-[180px]">Profile</div>
            <div className="w-[180px]">Location</div>
          </div>
          {bookings.map((booking) => (
            <div key={booking.id} className="flex text-sm  border-t py-1 px-2 ">
              <NavLink
                to={`${BOOKINGS_ADMIN_PATH}/${booking.id}`}
                className="underline w-[90px]"
              >
                # {booking.id.substring(booking.id.length - 7)}
              </NavLink>
              <NavLink
                className="w-[180px] underline"
                to={`${USERS_ADMIN_PATH}/${booking.profileId}`}
              >
                {booking.fullName}
              </NavLink>
              <NavLink
                to={`${LOCATIONS_ADMIN_PATH}/${booking.locationId}`}
                className="underline"
              >
                {booking.location.name}
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function generateArrayWithHalfSteps(
  start: number,
  end: number
): { asNumber: number; asTime: string }[] {
  if (start > end) {
    throw new Error("Start number must be less than end number.");
  }

  const array: { asNumber: number; asTime: string }[] = [];
  for (let i = start; i <= end; i += 0.5) {
    const hours = Math.floor(i);
    const minutes = (i - hours) * 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const time = `${formattedHours}:${formattedMinutes}`;
    array.push({
      asNumber: i,
      asTime: time,
    });
  }

  return array;
}
