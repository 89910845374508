import { Entry as EntryAdmin } from "admin/views/entry";
import { useAuth } from "auth/hooks/use-auth";
import { SignInView } from "auth/views/sign-in";
import "./index.css";
import "react-day-picker/dist/style.css";

function App() {
  const { isAdmin, isSuperadmin, isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <SignInView />;
  }

  if (isAdmin || isSuperadmin) {
    return <EntryAdmin />;
  }

  return <div>Unauthorized</div>;
}

export default App;
