import { DialogTitle } from "@radix-ui/react-dialog";
import {
  addAdminStationLocker,
  FETCH_ADMIN_STATION_DETAIL_QUERY,
  updateAdminStationLocker,
} from "admin/endpoints";
import { StationLocker } from "admin/types";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { ModalProps } from "common/types";
import { Button } from "design-system/button";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { DialogFooter, DialogHeader } from "shad/components/ui/dialog";

interface Props {
  stationId: string;
  locker?: StationLocker;
}

interface FormProps extends Omit<StationLocker, "id"> {
  stationId: string;
}

const defaultValues: FormProps = {
  name: "",
  description: "",
  isActive: true,
  address: 0,
  stationId: "",
  isOpen: false,
  type: "DEFAULT",
};

const getInitialFormData = (stationId: string, locker?: StationLocker) => {
  return { ...defaultValues, ...(locker ? locker : {}), stationId };
};

export const StationLockerModal: React.FC<ModalProps & Props> = ({
  handleClose,
  stationId,
  locker,
}) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: getInitialFormData(stationId, locker),
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => {
      if (locker) {
        return updateAdminStationLocker(locker.id, data);
      }
      return addAdminStationLocker(data);
    },
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([
          FETCH_ADMIN_STATION_DETAIL_QUERY,
          stationId,
        ]);
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutation.mutate(data);
  };

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Station locker</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 mt-2">
        <InputForward
          {...useNormalField({
            name: "name",
            register,
            errors,
            placeholder: "Name",
          })}
        />

        <InputForward
          {...useNormalField({
            name: "address",
            register,
            errors,
            placeholder: "Address",
            asNumber: true,
            noValidation: true,
          })}
        />

        <textarea
          {...useNormalField({
            name: "description",
            noValidation: true,
            register,
            errors,
            placeholder: "Description",
          })}
          className="w-full block rounded-md border p-3"
        />

        <CheckboxForward
          errors={errors}
          label="Is active"
          {...register("isActive")}
          isChecked={watch("isActive")}
        />
      </div>
      <DialogFooter>
        <Button
          isLoading={mutation.isLoading}
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          {locker ? "Update" : "Add"}
        </Button>
      </DialogFooter>
    </div>
  );
};
