import { Spinner } from "@chakra-ui/react";
import {
  fetchAdminProfile,
  FETCH_ADMIN_PROFILE_QUERY,
  FETCH_ADMIN_COMPANIES_QUERY,
  fetchAdminCompanies,
  removeProfilefromCompanyAdmins,
  addProfileToCompanyAdmins,
} from "admin/endpoints";
import { USERS_ADMIN_PATH } from "admin/routes";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { UserDetailsBookings } from "./user-details-bookings";
import { Header } from "admin/components/header";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "shad/components/ui/tabs";
import { Card, CardContent } from "shad/components/ui/card";
import { useAuth } from "auth/hooks/use-auth";
import { ReactNode } from "react";
import { toast } from "common/utils";
import { Profile } from "user/types";
import clsx from "clsx";

export const UserDetailsView = () => {
  const { isSuperadmin } = useAuth();
  const { id } = useParams<{ id: string }>();
  const { data: profile, isLoading } = useQuery(
    [FETCH_ADMIN_PROFILE_QUERY, id],
    () => {
      if (id) {
        return fetchAdminProfile(id);
      }

      return null;
    }
  );

  if (!profile || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="space-y-4">
      <Header
        title={`${profile.firstName} ${profile.lastName}`}
        subtitle={
          <NavLink className="underline" to={USERS_ADMIN_PATH}>
            Users
          </NavLink>
        }
      />
      <Tabs defaultValue="profile">
        <TabsList className="max-w-[400px] bg-gray-200">
          <TabsTrigger value="profile">Profile</TabsTrigger>
          <TabsTrigger value="bookings">Bookings</TabsTrigger>
        </TabsList>
        <TabsContent value="bookings">
          <UserDetailsBookings />
        </TabsContent>
        <TabsContent value="profile">
          <Card>
            <CardContent>
              <div className="pt-4">
                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Full name</div>
                  <div>
                    {profile.firstName} {profile.lastName}
                  </div>
                </div>
                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Email</div>
                  <div>{profile.email}</div>
                </div>

                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Address</div>
                  <div>
                    {profile.street}, {profile.city},<br /> {profile.postcode},{" "}
                    {profile.country}
                  </div>
                </div>

                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Language selected</div>
                  <div>{profile.language}</div>
                </div>

                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Phone number</div>
                  <div>{profile.phoneNumber}</div>
                </div>

                <div className="border-b mb-3 pb-3">
                  <div className="font-bold">Date of birth</div>
                  <div>{profile.dateOfBirth}</div>
                </div>

                <div className="">
                  <div className="font-bold">Communications by email</div>
                  <div>{profile.informMeByEmail ? "Yes" : "No"}</div>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
      {isSuperadmin && <ProfileCompanyRelation profile={profile} />}
    </div>
  );
};

const CompanyCheckbox = ({
  onChange,
  label,
  subTitle,
  checked,
}: {
  onChange: () => Promise<void>;
  label: ReactNode;
  subTitle: ReactNode;
  checked: boolean;
}) => {
  return (
    <label
      className={clsx(
        "p-4 flex items-start gap-2 rounded-md cursor-pointer bg-gray-50 hover:bg-purple-50",
        checked && "bg-purple-100"
      )}
    >
      <input
        type="radio"
        name="company-relation"
        className="relative top-[6px]"
        checked={checked}
        onChange={onChange}
      />
      <div>
        <div className={clsx(checked && "font-bold")}>{label}</div>
        {checked && <div>{subTitle}</div>}
      </div>
    </label>
  );
};

const ProfileTypeHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Card>
      <CardContent>
        <div className="pt-4">
          <div className="font-bold">User type</div>
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

export const ProfileCompanyRelation = ({ profile }: { profile: Profile }) => {
  const qc = useQueryClient();
  const { data: companies } = useQuery([FETCH_ADMIN_COMPANIES_QUERY], () =>
    fetchAdminCompanies()
  );
  const { mutateAsync: removeAsync } = useMutation((profileId: string) =>
    removeProfilefromCompanyAdmins(profileId)
  );
  const { mutateAsync: addAsync } = useMutation(
    ({ profileId, companyId }: { profileId: string; companyId: string }) =>
      addProfileToCompanyAdmins(profileId, companyId)
  );

  const onRemove = async (profileId: string) => {
    const data = await removeAsync(profileId);
    toast.success("Done");

    qc.setQueryData([FETCH_ADMIN_PROFILE_QUERY, profile.id], data);
  };

  const onAdd = async (profileId: string, companyId: string) => {
    const data = await addAsync({ profileId, companyId });
    toast.success("Done");

    qc.setQueryData([FETCH_ADMIN_PROFILE_QUERY, profile.id], data);
  };

  if (profile.role === "SUPERADMIN") {
    return (
      <ProfileTypeHeader>
        <div className="p-4 mt-4 flex items-start gap-2 rounded-md cursor-pointer bg-purple-50">
          This user is a SuperAdmin
        </div>
      </ProfileTypeHeader>
    );
  }

  if (profile.email.includes("onefit")) {
    return (
      <ProfileTypeHeader>
        <div className="p-4 mt-4 flex items-start gap-2 rounded-md cursor-pointer bg-purple-50">
          This user is a OneFit user
        </div>
      </ProfileTypeHeader>
    );
  }

  return (
    <ProfileTypeHeader>
      <form className="space-y-1 mt-4">
        <CompanyCheckbox
          checked={!profile.companyId}
          label="Normal user"
          subTitle="This user does not have access to the admin"
          onChange={() => onRemove(profile.id)}
        />

        {companies?.map((company) => (
          <CompanyCheckbox
            key={company.id}
            label={`Admin for ${company.name}`}
            checked={profile.companyId === company.id}
            subTitle={`This user has access to ${company.name} admin`}
            onChange={() => onAdd(profile.id, company.id)}
          />
        ))}
      </form>
    </ProfileTypeHeader>
  );
};
