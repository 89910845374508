import { HStack, Heading, Link } from "@chakra-ui/react";
import { fetchAdminBooking, FETCH_ADMIN_BOOKING_QUERY } from "admin/endpoints";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SLink } from "design-system/s-link";
import { LOCATIONS_ADMIN_PATH, USERS_ADMIN_PATH } from "admin/routes";
import { Booking } from "admin/types";
import { convertNumberToTime } from "common/utils";
import { TIMEZONE } from "user/utils";
import { formatInTimeZone } from "date-fns-tz";
import { Spinner } from "phosphor-react";
import { CopyClipboard } from "admin/components/copy-clipboard";
import { getBadgeVariant } from "admin/utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "shad/components/ui/card";
import { Badge } from "design-system/badge";

export const BookingDetailsView: React.FC = () => {
  const { id } = useParams();

  const { data } = useQuery([FETCH_ADMIN_BOOKING_QUERY, id], () => {
    if (id) {
      return fetchAdminBooking(id);
    }

    return null;
  });

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-4 flex-1">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Booking User</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <Heading size="sm">Name</Heading>
                  <SLink
                    to={`${USERS_ADMIN_PATH}/${data.profileId}`}
                    textDecoration="underline"
                  >
                    {data.fullName}
                  </SLink>
                </div>
                <div>
                  <Heading size="sm">E-mail</Heading>
                  <HStack alignItems="center">
                    <Link
                      isExternal
                      href={`mailto:${data.email}`}
                      textDecoration="underline"
                    >
                      {data.email}
                    </Link>
                    <CopyClipboard value={data.email} />
                  </HStack>
                </div>
                <div>
                  <Heading size="sm">User ID</Heading>
                  <HStack alignItems="center">
                    <div>{data.profileId}</div>
                    <CopyClipboard value={data.profileId} />
                  </HStack>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="space-y-4">
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="text-lg">Booking Location</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <Heading size="sm">Name</Heading>
                  <SLink
                    to={`${LOCATIONS_ADMIN_PATH}/${data.locationId}`}
                    textDecoration="underline"
                  >
                    {data.location?.name}
                  </SLink>
                </div>
                <div>
                  <Heading size="sm">Address</Heading>
                  <p>
                    {data.location?.street} {data.location?.postcode}
                  </p>
                </div>
                <div>
                  <Heading size="sm">Location ID</Heading>
                  <HStack alignItems="center">
                    <div>{data.locationId}</div>
                    <CopyClipboard value={data.locationId} />
                  </HStack>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="space-y-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-lg">Booking Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <Heading size="sm">Date</Heading>
                <p>{parseBookedDate(data)}</p>
              </div>
              <div>
                <Heading size="sm">Boards</Heading>
                <p>{data.boards}</p>
              </div>
              <div>
                <Heading size="sm">Created at</Heading>
                <p>
                  {formatInTimeZone(data.createdAt, TIMEZONE, "dd MMM - HH:mm")}
                </p>
              </div>
              <div>
                <Heading size="sm">Updated at</Heading>
                <p>
                  {formatInTimeZone(data.updatedAt, TIMEZONE, "dd MMM - HH:mm")}
                </p>
              </div>
              <div className="space-y-4">
                <div>
                  <Heading size="sm">Status</Heading>
                  <div>
                    <Badge variant={getBadgeVariant(data.status)}>
                      {data.status}
                    </Badge>
                  </div>
                </div>
                <div>
                  <Heading size="sm">Discount Code</Heading>
                  <p>{Boolean(data.discount) ? data.discount : "-"}</p>
                </div>
                <div>
                  <Heading size="sm">Pay by Link</Heading>
                  <HStack alignItems="center">
                    <Link
                      isExternal
                      href={data.payByLink}
                      textDecoration="underline"
                    >
                      {data.payByLink}
                    </Link>
                    <CopyClipboard value={data.payByLink} />
                  </HStack>
                </div>
                <div>
                  <Heading size="sm">Booking ID</Heading>
                  <HStack alignItems="center">
                    <div>{data.id}</div>
                    <CopyClipboard value={data.id} />
                  </HStack>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const parseBookedDate = (booking: Booking) => {
  const startHour = convertNumberToTime(booking.startHour);
  const endHour = convertNumberToTime(booking.endHour);
  const date = formatInTimeZone(booking.dateString, TIMEZONE, "dd MMM yyyy");
  return `${date} from ${startHour} to ${endHour}`;
};
