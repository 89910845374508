import { HomeView } from "admin/views/home";
import { Route } from "common/types";
import {
  Buildings,
  CirclesFour,
  DotsThreeCircle,
  Ticket,
  Users,
  Atom,
  Waves,
} from "phosphor-react";
import { Outlet } from "react-router-dom";
import { AreasView } from "./views/areas";
import { AreaDetails } from "./views/areas/area-details";
import { BookingDetailsView } from "./views/booking-details";
import { BookingsView } from "./views/bookings";
import { DiscountsView } from "./views/discounts";
import { UserDetailsView } from "./views/users/user-details";
import { LocationDetailsView } from "./views/locations/location-details";
import { StationDetailView } from "./views/stations/station-detail";
import { UsersView } from "./views/users";
import { CompaniesView } from "./views/companies";
import { Blocks, MapIcon, MonitorIcon } from "lucide-react";
import { MonitoringView } from "./views/monitoring";
import { BookingByTimes } from "./views/home/bookings-by-time";
import { LocationsView } from "./views/locations";
import { StationsView } from "./views/stations";

export const HOME_ADMIN_PATH = "/";
export const AREAS_ADMIN_PATH = "/areas";
export const AREA_ADMIN_PATH = AREAS_ADMIN_PATH + "/:id";
export const AREA_LOCATION_ADMIN_PATH = AREA_ADMIN_PATH + "/:locationId";

export const USERS_ADMIN_PATH = "/users";
export const USERS_DETAILS_ADMIN_PATH = USERS_ADMIN_PATH + "/:id";

export const LOCATIONS_ADMIN_PATH = "/locations";
export const LOCATION_ADMIN_PATH = LOCATIONS_ADMIN_PATH + "/:id";

export const STATIONS_ADMIN_PATH = "/stations";
export const STATION_DETAIL_ADMIN_PATH = STATIONS_ADMIN_PATH + "/:id";

export const BOOKINGS_ADMIN_PATH = "/bookings";
export const BOOKING_ADMIN_PATH = BOOKINGS_ADMIN_PATH + "/:id";

export const ACTIVE_BOARDS_ADMIN_PATH = "/active-boards";

export const DISCOUNTS_ADMIN_PATH = "/discounts";

export const businessRoutes: Route[] = [
  {
    path: HOME_ADMIN_PATH,
    end: true,
    label: "Home",
    icon: <CirclesFour size={16} />,
    element: <HomeView />,
  },
  {
    path: USERS_ADMIN_PATH,
    end: true,
    label: "Users",
    icon: <Users size={16} />,
    element: <UsersView />,
  },
  {
    path: AREAS_ADMIN_PATH,
    label: "Areas",
    icon: <Buildings size={16} />,
    element: <Outlet />,
    children: [
      {
        path: "",
        label: "Areas",
        icon: <Buildings size={16} />,
        element: <AreasView />,
      },
      {
        path: AREA_ADMIN_PATH,
        element: <AreaDetails />,
        hideFromMenu: true,
      },
    ],
  },
  {
    path: LOCATIONS_ADMIN_PATH,
    label: "Locations",
    icon: <MapIcon size={16} />,
    element: <Outlet />,
    children: [
      { path: "", element: <LocationsView /> },
      {
        path: LOCATION_ADMIN_PATH,
        element: <LocationDetailsView />,
      },
    ],
  },
  {
    path: STATIONS_ADMIN_PATH,
    label: "Stations",
    icon: <Blocks size={16} />,
    element: <Outlet />,
    children: [
      { path: "", element: <StationsView /> },
      {
        path: STATION_DETAIL_ADMIN_PATH,
        element: <StationDetailView />,
      },
    ],
  },
  {
    path: BOOKINGS_ADMIN_PATH,
    label: "Bookings",
    icon: <DotsThreeCircle size={16} />,
    element: <BookingsView />,
  },
  {
    path: ACTIVE_BOARDS_ADMIN_PATH,
    label: "Active Boards",
    icon: <Waves size={16} />,
    element: <BookingByTimes />,
  },
  {
    hideFromMenu: true,
    path: BOOKING_ADMIN_PATH,
    element: <BookingDetailsView />,
  },
  {
    hideFromMenu: true,
    path: STATION_DETAIL_ADMIN_PATH,
    element: <StationDetailView />,
  },
  {
    hideFromMenu: true,
    path: USERS_DETAILS_ADMIN_PATH,
    element: <UserDetailsView />,
  },
  {
    path: DISCOUNTS_ADMIN_PATH,
    element: <DiscountsView />,
    icon: <Ticket size={16} />,
    label: "Discounts",
  },
];

export const superadminRoutes = [
  {
    path: "/monitoring",
    label: "Monitoring",
    icon: <MonitorIcon size={16} />,
    element: <MonitoringView />,
  },
  {
    path: "/companies",
    label: "Companies",
    icon: <Atom size={16} />,
    element: <CompaniesView />,
  },
];
