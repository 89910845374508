import { Button } from "design-system/button";

const loop = (n: number) => Array.from({ length: n }, (_, i) => i + 1);

interface PaginationProps {
  totalPages: number | undefined;
  currentPage: number | undefined;
  onPageChange: (page: number) => void;
}

export const PaginationControlled: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const pages = loop(totalPages || 0);
  return (
    <div>
      {pages.map((pagNum) => (
        <Button
          className="inline-block mr-2 mb-2 min-w-[32px] h-8 text-xs p-0"
          variant={currentPage === pagNum ? "default" : "outline"}
          aria-label="pagination"
          key={`pag-${pagNum}`}
          onClick={() => {
            onPageChange(pagNum);
          }}
        >
          {pagNum}
        </Button>
      ))}
    </div>
  );
};
