import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react";
import { FieldErrors } from "react-hook-form";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  errors?: FieldErrors;
  label: ReactNode;
  isChecked?: boolean;
}
export const CheckboxForward = forwardRef(
  (
    { name, isChecked, label, ...rest }: CheckboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <label className="inline-flex cursor-pointer items-center relative">
        <input
          type="checkbox"
          name={name}
          ref={ref}
          checked={isChecked}
          className="hidden peer"
          {...rest}
        />
        <div className="w-4 h-4 flex items-center justify-center rounded border-2 border-gray-300 peer-checked:bg-primary peer-checked:border-primary"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="white"
          className="w-3 h-3 text-white hidden peer-checked:block absolute top-1.5 left-0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>
        <span className="ml-2 text-inherit">{label}</span>
      </label>
    );
  }
);

CheckboxForward.displayName = "CheckboxForward";
