import { FormControl, FormLabel } from "@chakra-ui/react";
import {
  addDiscount,
  fetchAdminArea,
  fetchAdminAreas,
  fetchAdminLocation,
  fetchAdminLocations,
  fetchAdminProfile,
  fetchAdminProfiles,
  FETCH_ADMIN_DISCOUNTS_QUERY,
  updateDiscount,
} from "admin/endpoints";
import { SAutocomplete } from "common/components/autocomplete";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { Discount, ModalProps } from "common/types";
import { delayQuery } from "common/utils";
import { Button } from "design-system/button";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { DialogFooter, DialogHeader } from "shad/components/ui/dialog";

interface Props {
  discount?: Discount;
}

interface FormProps extends Omit<Discount, "id"> {}

const defaultValues: FormProps = {
  areaId: null,
  profileId: null,
  locationId: null,
  value: 0,
  code: "",
  timeLimit: null,
  boardsLimit: null,
  startAt: null,
  endAt: null,
  isActive: true,
  isOneTimeUse: false,
};

const delayProfiles = delayQuery<ReturnType<typeof fetchAdminProfiles>>();
const delayAreas = delayQuery<ReturnType<typeof fetchAdminAreas>>();
const delayLocations = delayQuery<ReturnType<typeof fetchAdminLocations>>();

const getInitialFormData = (discount: Discount) => {
  return {
    ...defaultValues,
    ...discount,
    areaId: discount.areaId || null,
    profileId: discount.profileId || null,
    locationId: discount.locationId || null,
  };
};

export const DiscountModal: React.FC<ModalProps & Props> = ({
  handleClose,
  discount,
}) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: discount ? getInitialFormData(discount) : defaultValues,
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => (discount ? updateDiscount(data) : addDiscount(data)),
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([FETCH_ADMIN_DISCOUNTS_QUERY]);
      },
    }
  );

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  return (
    <div>
      <DialogHeader>Add Discount</DialogHeader>
      <div className="space-y-4">
        <FormControl isInvalid={!!errors.profileId}>
          <FormLabel>Profile</FormLabel>
          <SAutocomplete
            isClearable
            placeholder="Search for a profile"
            menuPlacement="bottom"
            initialValue={getValues().profileId}
            onChange={(ev) => {
              setValue("profileId", ev?.value || "", { shouldDirty: true });
            }}
            fetchInitialValue={async (val) => {
              const profile = await fetchAdminProfile(val);

              return {
                value: profile.id,
                label: `${profile.firstName || ""} ${profile.lastName || ""} (${
                  profile.email
                })`,
              };
            }}
            loadOptions={async (inputValue) => {
              if (inputValue.length < 3) return [];
              const profiles = await delayProfiles(() =>
                fetchAdminProfiles({ q: inputValue })
              );
              return profiles?.result?.map((profile) => ({
                value: profile.id,
                label: `${profile.firstName || ""} ${profile.lastName || ""} (${
                  profile.email
                })`,
              }));
            }}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.areaId}>
          <FormLabel>Area</FormLabel>
          <SAutocomplete
            isClearable
            initialValue={getValues().areaId}
            placeholder="Search for an area"
            menuPlacement="bottom"
            onChange={(ev) =>
              setValue("areaId", ev?.value || "", { shouldDirty: true })
            }
            fetchInitialValue={async (val) => {
              const area = await fetchAdminArea(val);

              return {
                value: area.id,
                label: `${area.name || ""}`,
              };
            }}
            loadOptions={async (inputValue) => {
              if (inputValue.length < 3) return [];
              const areas = await delayAreas(() =>
                fetchAdminAreas({ q: inputValue })
              );
              return areas?.result?.map((area) => ({
                value: area.id,
                label: `${area.name || ""}`,
              }));
            }}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.locationId}>
          <FormLabel>Location</FormLabel>
          <SAutocomplete
            isClearable
            initialValue={getValues().locationId}
            placeholder="Search for a location"
            menuPlacement="bottom"
            onChange={(ev) =>
              setValue("locationId", ev?.value || "", { shouldDirty: true })
            }
            fetchInitialValue={async (val) => {
              const location = await fetchAdminLocation(val);

              return {
                value: location.id,
                label: `${location.name || ""}`,
              };
            }}
            loadOptions={async (inputValue) => {
              if (inputValue.length < 3) return [];
              const locations = await delayLocations(() =>
                fetchAdminLocations({ q: inputValue })
              );
              return locations?.result?.map((location) => ({
                value: location.id,
                label: `${location.name || ""}`,
              }));
            }}
          />
        </FormControl>

        <InputForward
          {...useNormalField({
            name: "value",
            register,
            errors,
            placeholder: "Value (in cents)",
            noValidation: true,
            asNumber: true,
          })}
          type="number"
          min={0}
        />

        <InputForward
          {...useNormalField({
            name: "timeLimit",
            register,
            errors,
            placeholder: "Duration limit",
            noValidation: true,
            asNumber: true,
          })}
          type="number"
          min={0}
          step="0.5"
        />

        <InputForward
          {...useNormalField({
            name: "boardsLimit",
            register,
            errors,
            placeholder: "Boards limit",
            noValidation: true,
            asNumber: true,
          })}
          type="number"
          min={0}
        />

        <InputForward
          {...useNormalField({
            name: "code",
            register,
            errors,
            placeholder: "Code",
          })}
        />
        <div>
          <CheckboxForward
            errors={errors}
            label="Is one time use"
            {...register("isOneTimeUse")}
            isChecked={watch("isOneTimeUse")}
          />
        </div>
        <CheckboxForward
          errors={errors}
          label="Is active"
          {...register("isActive")}
          isChecked={watch("isActive")}
        />
      </div>
      <DialogFooter>
        <Button
          isLoading={mutation.isLoading}
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          {discount ? "Update" : "Add"}
        </Button>
      </DialogFooter>
    </div>
  );
};
