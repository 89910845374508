import { t } from "i18next";

export const getRequiredValidator = (minValue = 3) => ({
  required: t("field_is_required", { ns: "common" }),
  minLength: {
    value: minValue,
    message: t("minimum_length_3", { ns: "common" }),
  },
});

export const getDateStringValidator = () => ({
  pattern: {
    value: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}$/,
    message: t("invalid_format_date", { ns: "common" }),
  },
  required: t("field_is_required", { ns: "common" }),
  length: 9,
});

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const getUuidValidator = () => ({
  pattern: {
    value: uuidRegex,
    message: "Invalid UUID",
  },
});

export const isUuid = (str?: string) => !!str?.match(uuidRegex);

export const getPhoneNumberValidator = () => ({
  pattern: {
    value: /^(?:\+)?\d+$/,
    message: t("invalid_format", { ns: "common" }),
  },
  required: t("field_is_required", { ns: "common" }),
});

export const getEmailValidator = () => ({
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: t("invalid_format", { ns: "common" }),
  },
  ...getRequiredValidator(),
});
