import { format } from "date-fns";

export const formatMoney = (
  money: number | undefined,
  additionalAttrs = {},
  withDots = false
) => {
  const res = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    ...additionalAttrs,
  }).format((money || 0) / 100);

  return withDots ? res.replace(",", ".") : res;
};

export const dateToString = (date: Date) => format(date, "yyyy-MM-dd");

export const dateToLocal = (date: Date, time: string) =>
  `${format(date, "yyyy-MM-dd")}T${time}`;
