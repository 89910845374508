import {
  FETCH_ADMIN_STATIONS_QUERY,
  FETCH_ADMIN_STATION_DETAIL_QUERY,
  updateAdminStation,
} from "admin/endpoints";
import { Station } from "admin/types";
import { useSnack } from "common/hooks/use-snack";
import { getRequiredValidator } from "common/validators";
import { Button } from "design-system/button";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { InputForward } from "common/components/input-forward";
import {
  CardContent,
  CardHeader,
  Card,
  CardTitle,
} from "shad/components/ui/card";
import { SelectForward } from "common/components/select-forward";

interface Props {
  item: Station | undefined;
}

export const StationForm: React.FC<Props> = ({ item }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
  } = useForm<Station>({
    mode: "onChange",
    defaultValues: getInitialFormData(item),
  });
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();

  const mutation = useMutation(
    async (data: Station) => updateAdminStation(data.id, data),
    {
      onSuccess: (_, station) => {
        successMessage("Done");
        queryClient.invalidateQueries([
          FETCH_ADMIN_STATION_DETAIL_QUERY,
          station.id,
        ]);
        queryClient.invalidateQueries([
          FETCH_ADMIN_STATIONS_QUERY,
          item?.locationId,
        ]);
      },
    }
  );

  const onSubmit = (data: Station) => mutation.mutate(data);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Update station</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <InputForward
            errors={errors}
            {...register("name", {
              ...getRequiredValidator(),
            })}
            placeholder="Name"
          />
          <InputForward
            errors={errors}
            {...register("ip", {
              ...getRequiredValidator(),
            })}
            placeholder="IP Address"
          />

          <SelectForward
            {...register("type", {
              required: true,
            })}
            placeholder="Type of station"
            options={[
              { label: "Chinese locks", value: "DEFAULT" },
              {
                label: "Metra locks",
                value: "METRA",
              },
              {
                label: "Manual (No locks)",
                value: "MANUAL",
              },
            ]}
            errors={errors}
          />

          <InputForward
            errors={errors}
            {...register("port", {
              ...getRequiredValidator(),
            })}
            placeholder="Port"
          />

          <div className="flex justify-end">
            <Button
              isLoading={mutation.isLoading}
              disabled={!(isValid && isDirty)}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const getInitialFormData = (item: Station | undefined) => {
  if (!item) return {};

  return item;
};
