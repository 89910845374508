import { forwardRef } from "react";
import { InputForwardProps } from "../input-forward";
import { SelectProps } from "@radix-ui/react-select";

interface Option {
  label: string;
  value: string;
}

interface SelectForwardProps extends InputForwardProps {
  options: Option[];
}

export const SelectForward = forwardRef<any, SelectForwardProps & SelectProps>(
  ({ errors, options, ...props }, ref) => (
    <label className="flex flex-col">
      {(props.placeholder || props.label) && (
        <div className="mb-2">{props.label || props.placeholder}</div>
      )}

      <select
        className="ring-1 ring-gray-300 outline-none focus:border-ring focus-visible:ring-ring focus-visible:ring-2 rounded-md text-sm p-2"
        ref={ref}
        {...props}
      >
        {props.placeholder && <option value="">{props.placeholder}</option>}
        {options.map((option: Option, index: number) => (
          <option key={`option-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="text-red">
        {errors[props.name] && <>{errors[props.name]?.message}</>}
      </div>
    </label>
  )
);

SelectForward.displayName = "SelectForward";
