import { Button, ButtonProps } from "design-system/button";
import { ReactElement, ReactNode, Ref, forwardRef, useState } from "react";

import {
  DialogContent,
  DialogHeader,
  DialogOverlay,
  Dialog,
} from "shad/components/ui/dialog";

export const ConfirmationButton = forwardRef(
  (
    {
      onConfirm,
      children,
      icon,
      ...props
    }: {
      children?: ReactNode;
      icon?: ReactElement;
      onConfirm: () => Promise<void> | void;
    } & ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const onClick = async () => {
      setLoading(true);
      await onConfirm();
      setLoading(false);
      setShowModal(false);
    };

    return (
      <div>
        <Button {...props} ref={ref} onClick={() => setShowModal(true)}>
          {icon || children}
        </Button>
        <Dialog open={showModal} onOpenChange={(state) => setShowModal(state)}>
          <DialogOverlay />
          <DialogContent>
            <DialogHeader>Are you sure?</DialogHeader>
            <div className="space-y-4">
              <div>Are you sure you want to perform this operation?</div>
              <Button isLoading={isLoading} onClick={onClick}>
                Continue
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
);

ConfirmationButton.displayName = "ConfirmationButton";
