import { Box, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  fetchAdminStation,
  FETCH_ADMIN_STATION_DETAIL_QUERY,
  openAdminLocker,
  deleteAdminLocker,
  readAdminLocker,
} from "admin/endpoints";
import { LOCATIONS_ADMIN_PATH } from "admin/routes";
import { StationLocker, StationLockerPopulated } from "admin/types";
import { modalState } from "common/atoms";
import { Button } from "design-system/button";
import { STable } from "design-system/s-table";
import { Circle, DotsThree, Spinner } from "phosphor-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { StationForm } from "../station-form";
import { useSnack } from "common/hooks/use-snack";
import { StationLockerModal } from "admin/modals/locker";
import { ConfirmationButton } from "common/components/confirmation-button";
import { queryClient } from "index";
import { Header } from "admin/components/header";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";
import { AxiosError } from "axios";
import { Badge } from "design-system/badge";
import { forwardRef } from "react";
import { toast } from "common/utils";

export const StationDetailView: React.FC<React.PropsWithChildren> = () => {
  const setModal = useSetRecoilState(modalState);
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_STATION_DETAIL_QUERY, id],
    async () => {
      if (id) {
        const station = await fetchAdminStation(id);

        return station;
      }

      return null;
    }
  );

  if (!data || isFetching) {
    return (
      <div className="animate-spin h-4 w-4">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="space-y-12">
      <div className="space-y-4">
        <Header
          title={data?.name}
          subtitle={
            <NavLink
              className="underline"
              to={`${LOCATIONS_ADMIN_PATH}/${data?.location?.id}`}
            >
              {data?.location?.name}
            </NavLink>
          }
        />
        <StationForm item={data} />
      </div>
      <div className="space-y-4">
        <Header title="Lockers">
          <div className="space-x-2">
            {/* <ReadAllLockersButton stationId={data.id} /> */}
            <Button
              className="self-end"
              onClick={() => {
                setModal({
                  key: "STATION_LOCKER",
                  component: StationLockerModal,
                  props: { stationId: data.id },
                });
              }}
            >
              Add
            </Button>
          </div>
        </Header>
        <STable
          columns={columns(data.type)}
          data={data.lockers}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
};

const columnHelper = createColumnHelper<StationLockerPopulated>();
const columns = (stationType: string) => [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("address", {
    header: () => <strong>#</strong>,
    cell: (info) => (
      <Badge variant="outline" className="text-xs">
        {info.getValue()}
      </Badge>
    ),
  }),
  columnHelper.accessor("isOpen", {
    header: () => (
      <Box as="strong" display="block" textAlign="center">
        {stationType === "MANUAL" ? "" : "Status"}
      </Box>
    ),
    cell: (info) => {
      if (stationType === "MANUAL") return null;
      return (
        <Flex justifyContent="center" align="center">
          {info.getValue() ? "Open" : "Closed"}
        </Flex>
      );
    },
  }),
  columnHelper.accessor("isActive", {
    header: () => (
      <Box as="strong" display="block" textAlign="center">
        Active
      </Box>
    ),
    cell: (info) => (
      <Flex justifyContent="center" align="center">
        <Circle
          size={16}
          weight="fill"
          color={info.getValue() ? "#3cde55" : "#ffb100"}
        />
      </Flex>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: () => <Box as="strong" textAlign="center"></Box>,
    cell: (info) => {
      return (
        <RowActions locker={info.row.original} stationType={stationType} />
      );
    },
  }),
];

const RowActions = ({
  locker,
  stationType,
}: {
  locker: StationLocker;
  stationType: string;
}) => {
  const setModal = useSetRecoilState(modalState);
  const onEdit = (locker: StationLocker) => {
    setModal({
      key: "STATION_LOCKER",
      component: StationLockerModal,
      props: {
        stationId: locker.stationId,
        locker,
      },
    });
  };
  const onClick = async () => {
    try {
      await deleteAdminLocker(locker.id);
      queryClient.invalidateQueries(FETCH_ADMIN_STATION_DETAIL_QUERY);
      toast.success({ message: "Done" });
    } catch (e) {
      toast.error({ message: (e as AxiosError).response?.data as string });
      console.log(e);
    }
  };
  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {stationType !== "MANUAL" && (
            <>
              <DropdownMenuItem asChild>
                <OpenLockerkButton lockerId={locker.id} />
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <ReadLockerkButton lockerId={locker.id} />
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuItem onClick={() => onEdit(locker)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-default focus-visible:ring-0"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const OpenLockerkButton = forwardRef<HTMLButtonElement, { lockerId: string }>(
  ({ lockerId }, ref) => {
    const { successMessage } = useSnack();
    const client = useQueryClient();
    const { id } = useParams<{ id: string }>();
    const { mutate, isLoading } = useMutation(
      () => {
        return openAdminLocker(lockerId);
      },
      {
        onSuccess: () => {
          successMessage("Station locker is now open");
          client.invalidateQueries([FETCH_ADMIN_STATION_DETAIL_QUERY, id]);
        },
      }
    );
    return (
      <Button
        {...ref}
        variant="ghost"
        aria-label="btn"
        isLoading={isLoading}
        onClick={() => mutate()}
        className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-default"
      >
        Open locker
      </Button>
    );
  }
);
OpenLockerkButton.displayName = "OpenLockerButton";

const ReadLockerkButton = forwardRef<HTMLButtonElement, { lockerId: string }>(
  ({ lockerId }, ref) => {
    const { successMessage } = useSnack();
    const { mutate, isLoading } = useMutation(
      () => {
        return readAdminLocker(lockerId);
      },
      {
        onSuccess: (response) => {
          successMessage(response);
        },
      }
    );
    return (
      <Button
        {...ref}
        variant="ghost"
        aria-label="btn"
        isLoading={isLoading}
        onClick={() => mutate()}
        className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-default"
      >
        Read locker
      </Button>
    );
  }
);
ReadLockerkButton.displayName = "ReadLockerButton";
