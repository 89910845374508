import {
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const PrivacyEn = () => {
  return (
    <>
      <Heading as="h1" size="lg">
        PRIVACY STATEMENT SUP SUP CLUB
      </Heading>
      <Stack spacing={4} mt={4}>
        <Text>
          When you want to rent a SUP board at Sup Sup Club, or when you place
          an order through our website (www.supsupclub.com), we require some
          personal data from you. We would like to explain in this Privacy
          Statement why we need this personal data and what we do with it.
        </Text>
        <Text>
          Sup Sup Club considers privacy to be important and is committed to
          protecting the privacy of its Participants. We carefully store,
          process, and secure personal data. We comply with all obligations
          arising from the General Data Protection Regulation (Regulation (EU)
          2016/679) (GDPR) and other privacy legislation.
        </Text>
        <Text>
          Sup Sup Club B.V. and its office are located at Zandhoek 22 (1013 KT)
          in Amsterdam. Its rental stations are spread across several locations
          throughout the Netherlands (see the specific addresses on our website
          under locations).
        </Text>
        <Heading size="md">What personal data do we collect?</Heading>
        <UnorderedList>
          <ListItem>first and last name;</ListItem>
          <ListItem>gender;</ListItem>
          <ListItem>date of birth;</ListItem>
          <ListItem>e-mail address;</ListItem>
          <ListItem>(mobile) phone number;</ListItem>
          <ListItem>address details;</ListItem>
          <ListItem>bank details;</ListItem>
          <ListItem>
            information around your visit to Sup Sup Club (incoming and outgoing
            time of rental SUP boards and booked lessons);
          </ListItem>
          <ListItem>transaction data from orders; and</ListItem>
          <ListItem>
            IP addresses, operating system, and browser type and timestamps.
          </ListItem>
        </UnorderedList>
        <Text>We use this data for the following purposes:</Text>
        <UnorderedList>
          <ListItem>drafting, sending, and preparing invoices;</ListItem>
          <ListItem>providing information;</ListItem>
          <ListItem>improving our services;</ListItem>
          <ListItem>
            answering questions asked via, for example, the website;
          </ListItem>
          <ListItem>
            possibly providing information regarding new products and services;
            and
          </ListItem>
          <ListItem>
            fulfilling our obligations arising from contracts we have entered
            into with you, as well as for billing and delivery.
          </ListItem>
        </UnorderedList>
        <Text>
          The basis for processing is the performance of the agreement with you
          and legal obligations.
        </Text>
        <Heading size="md">Provision of personal data to third parties</Heading>
        <Text>
          We do not sell, trade or provide personal data to third parties
          without your explicit consent to do so. Your personal data will only
          be shared if we are legally obliged to do so.
        </Text>
        <Heading size="md">Processors</Heading>
        <Text>
          To provide our services, we use other processors, such as Adyen for
          payments. We ensure that our processors also process personal data
          according to the GDPR.
        </Text>
        <Heading size="md">Your rights</Heading>
        <Text>
          You always have the right to access the personal data we hold on you.
          If this data is incorrect, you can help us correct it or ask us to
          restrict processing. You also have the right to ask us to delete your
          personal data or to make your personal data available to you on a data
          carrier. Requests to this effect will always be honoured. You can
          exercise these rights by sending an email to the contact address on
          the site. In addition, you can always object to the processing of your
          personal data.
        </Text>
        <Heading size="md">How long do we keep your personal data?</Heading>
        <Text>
          We keep your personal data for as long as is necessary to fulfil the
          purposes for which we process your personal data. After you cancel
          your membership, your data will be kept for a maximum of 7 years.
        </Text>
        <Heading size="md">Security</Heading>
        <Text>
          Due to the nature of our services and the trust you must be able to
          place in us, taking into account the state of the art implementation
          costs, we take appropriate technical and organisational measures to
          ensure that the processing of personal data takes place in accordance
          with the GDPR.
        </Text>
        <Heading size="md">Personal Data Authority</Heading>
        <Text>
          Of course, we will be happy to help you if you have complaints about
          the processing of your personal data. Under the GDPR, you also have
          the right to lodge a complaint with the Personal Data Authority
          against such processing of personal data. You can contact the Personal
          Data Authority for this purpose.
        </Text>
        <Heading size="md">Adjustments</Heading>
        <Text>
          Sup Sup Club reserves the right to amend and modify its privacy
          statement.
        </Text>
        <Text>
          If you have any questions, please contact us at{" "}
          <Link href="mailto:info@supsupclub.com">info@supsupclub.com</Link>.
        </Text>
        <Text>This privacy statement was last updated on April 1, 2023.</Text>
      </Stack>
    </>
  );
};
