import { useAuth } from "auth/hooks/use-auth";
import { Routes, Route } from "react-router-dom";
import { getRoutes } from "routes";

export const Router: React.FC<React.PropsWithChildren> = () => {
  const { isSuperadmin } = useAuth();
  return (
    <Routes>
      {getRoutes(isSuperadmin).map((route) => {
        let children = null;

        if (route.children) {
          children = route.children.map((child) => (
            <Route key={child.path} path={child.path} element={child.element} />
          ));
        }

        if (children) {
          return (
            <Route key={route.path} path={route.path} element={route.element}>
              {children}
            </Route>
          );
        }
        return (
          <Route key={route.path} path={route.path} element={route.element} />
        );
      })}
    </Routes>
  );
};
