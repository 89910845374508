import { Box, Flex, Stack } from "@chakra-ui/react";
import { modalState } from "common/atoms";
import { Button } from "design-system/button";
import { Circle, DotsThree } from "phosphor-react";
import { useSetRecoilState } from "recoil";
import { LocationModal } from "admin/modals/location";
import { STable } from "design-system/s-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Location } from "common/types";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchAdminAreasLocation,
  FETCH_ADMIN_AREAS_LOCATION_QUERY,
  deleteAdminLocation,
} from "admin/endpoints";
import { LOCATIONS_ADMIN_PATH } from "admin/routes";
import { ConfirmationButton } from "common/components/confirmation-button";
import { Link } from "design-system/links";
import { queryClient } from "index";
import { Header } from "admin/components/header";
import clsx from "clsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";
import { AxiosError } from "axios";
import { toast } from "common/utils";

export const AreaLocationsView: React.FC<React.PropsWithChildren> = () => {
  const setModal = useSetRecoilState(modalState);
  const { id } = useParams();

  const { data: locations, isLoading } = useQuery(
    [FETCH_ADMIN_AREAS_LOCATION_QUERY, id],
    () => {
      if (id) {
        return fetchAdminAreasLocation(id);
      }

      return null;
    }
  );

  return (
    <div className="space-y-4">
      <Header title="Locations">
        <Button
          className="self-end"
          onClick={() =>
            setModal({
              key: "LOCATION",
              component: LocationModal,
              props: { areaId: id },
            })
          }
        >
          Add
        </Button>
      </Header>
      <STable isLoading={isLoading} columns={columns} data={locations} />
    </div>
  );
};

const columnHelper = createColumnHelper<Location>();
const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => (
      <Link to={`${LOCATIONS_ADMIN_PATH}/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor(() => "address", {
    id: "address",
    header: () => <strong>Address</strong>,
    cell: (info) => (
      <Stack spacing={1}>
        <div>{info.row.original.street}</div>
        <div>{info.row.original.postcode}</div>
      </Stack>
    ),
  }),
  columnHelper.accessor("weekDays", {
    header: () => <strong>Week days</strong>,
    cell: (info) => {
      const weekDays = info.getValue();

      if (weekDays.length === 7) {
        return (
          <div>
            <div className="rounded-sm bg-green-500 text-white py-1 px-2 text-xs inline-block">
              Whole week
            </div>
          </div>
        );
      }

      return (
        <Stack spacing={1} direction="row" alignItems="center" wrap="wrap">
          {[1, 2, 3, 4, 5, 6, 0].map((dayNr) => (
            <div
              key={`day-${dayNr}`}
              className={clsx(
                "rounded-sm py-1 px-2 text-xs me-1 mb-1",
                weekDays.includes(`${dayNr}`)
                  ? "bg-green-500 text-white"
                  : "bg-gray-200 "
              )}
            >
              {getDayOfWeek(dayNr)}
            </div>
          ))}
        </Stack>
      );
    },
  }),
  columnHelper.accessor("isActive", {
    header: () => (
      <Box as="strong" display="block" textAlign="center">
        Active
      </Box>
    ),
    cell: (info) => (
      <Flex justifyContent="center" align="center">
        <Circle
          size={16}
          weight="fill"
          color={info.getValue() ? "#3cde55" : "#ffb100"}
        />
      </Flex>
    ),
  }),

  columnHelper.display({
    id: "actions",
    header: () => <div />,
    cell: (info) => <RowActions location={info.row.original} />,
  }),
];

const RowActions = ({ location }: { location: Location }) => {
  const onClick = async () => {
    try {
      await deleteAdminLocation(location.id);
      queryClient.invalidateQueries(FETCH_ADMIN_AREAS_LOCATION_QUERY);
      toast.success({ message: "Done" });
    } catch (e) {
      toast.error({ message: (e as AxiosError).response?.data as string });
      console.log(e);
    }
  };
  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-default"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

function getDayOfWeek(dayNum: number) {
  // create a new Date object with the current date
  const date = new Date();

  // set the day of the week on the Date object
  // using the dayNum argument
  date.setDate(date.getDate() - date.getDay() + dayNum);

  // create a DateTimeFormat object with the French locale
  const dateTimeFormat = new Intl.DateTimeFormat("en", { weekday: "short" });

  // format the date using the DateTimeFormat object
  const day = dateTimeFormat.format(date);

  // return the day of the week as a string
  return day;
}
