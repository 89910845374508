import { LocationEditForm } from "admin/forms/location-edit";
import { LocationStationsView } from "admin/views/stations/location-stations";

export const LocationDetailsView = () => {
  return (
    <div className="space-y-12">
      <LocationEditForm />
      <LocationStationsView />
    </div>
  );
};
