import { Card, CardBody, Stack, Text } from "@chakra-ui/react";
import { supxios } from "api";
import { useSnack } from "common/hooks/use-snack";
import { getRequiredValidator } from "common/validators";
import { Button } from "design-system/button";
import { PageTitle } from "design-system/titles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { InputForward } from "common/components/input-forward";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SIGN_IN_PATH } from "auth/routes";

interface FormProps {
  password: string;
}

export const ResetPasswordView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const [params] = useSearchParams();
  const token = params.get("token");
  const navigate = useNavigate();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      password: "",
    },
  });

  const mutation = useMutation<any, any, FormProps, any>(
    (data) => {
      return supxios.post("password/reset", { ...data, token });
    },
    {
      onSuccess: () => {
        successMessage(t("snack.updated"));
        navigate(SIGN_IN_PATH);
      },
    }
  );

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  if (!token) {
    return <Text>Token is missing</Text>;
  }

  return (
    <Stack>
      <PageTitle>{t("pageTitle.resetPassword")}</PageTitle>
      <Card>
        <CardBody>
          <Stack spacing={4}>
            <InputForward
              placeholder={t("placeholder.newPassword")}
              errors={errors}
              type="password"
              autoComplete="new-password"
              {...register("password", { ...getRequiredValidator() })}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              isLoading={mutation.isLoading}
            >
              {t("pageTitle.resetPassword")}
            </Button>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};
