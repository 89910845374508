import { PaginationControlled } from "admin/components/pagination-controlled";
import {
  fetchAdminProfileBookings,
  FETCH_ADMIN_BOOKINGS_QUERY,
} from "admin/endpoints";
import { STable } from "design-system/s-table";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { bookingsColumns } from "admin/views/bookings";

export const UserDetailsBookings: React.FC = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_BOOKINGS_QUERY, page, id],
    () => {
      if (id) {
        return fetchAdminProfileBookings(id, { page });
      }
    }
  );

  return (
    <div className="space-y-4">
      <STable
        columns={bookingsColumns}
        data={data?.result}
        isLoading={isFetching}
      />
      {data && (
        <PaginationControlled
          currentPage={data?.currentPage}
          totalPages={data?.totalPages}
          onPageChange={setPage}
        />
      )}
    </div>
  );
};
