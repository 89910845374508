import React, { forwardRef } from "react";
import { FieldErrorsImpl } from "react-hook-form";
import { Input, InputProps } from "shad/components/ui/input";

export interface InputForwardProps {
  errors: Partial<FieldErrorsImpl>;
  name: string;
  placeholder?: string;
  label?: string | React.ReactElement | null;
}

export const InputForward = forwardRef<any, InputForwardProps & InputProps>(
  ({ errors, ...props }, ref) => (
    <div className="space-y-1">
      <label htmlFor={props.name}>{props.label || props.placeholder}</label>
      <div className="space-y-1">
        <Input id={props.name} ref={ref} {...props} />
        {errors[props.name] && (
          <div className="text-xs text-red-500">
            <>{errors[props.name]?.message}</>
          </div>
        )}
      </div>
    </div>
  )
);

InputForward.displayName = "InputForward";
