import {
  FETCH_ADMIN_AREAS_QUERY,
  deleteAdminArea,
  fetchAdminAreas,
} from "admin/endpoints";
import { AdminArea } from "admin/types";
import { modalState } from "common/atoms";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { AreaModal } from "admin/modals/area";
import { createColumnHelper } from "@tanstack/react-table";
import { STable } from "design-system/s-table";
import { AREAS_ADMIN_PATH } from "admin/routes";
import { Link } from "design-system/links";
import { ConfirmationButton } from "common/components/confirmation-button";
import { DotsThree } from "phosphor-react";
import { queryClient } from "index";
import { Button } from "design-system/button";
import { Header } from "admin/components/header";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";
import { NavLink } from "react-router-dom";
import { AxiosError } from "axios";
import { useAuth } from "auth/hooks/use-auth";
import { toast } from "common/utils";
import { PaginationControlled } from "admin/components/pagination-controlled";
import { useDebounce } from "common/hooks/use-debounce";
import { Input } from "shad/components/ui/input";

const columnHelper = createColumnHelper<AdminArea>();
const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => (
      <Link to={`${AREAS_ADMIN_PATH}/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: () => <div />,
    cell: (info) => {
      return <RowActions area={info.row.original} />;
    },
  }),
];

const RowActions = ({ area }: { area: AdminArea }) => {
  const { isSuperadmin } = useAuth();
  const onClick = async () => {
    try {
      await deleteAdminArea(area.id);
      queryClient.invalidateQueries(FETCH_ADMIN_AREAS_QUERY);
      toast.success({ message: "Done" });
    } catch (e) {
      toast.error({ message: (e as AxiosError).response?.data as string });
      console.log(e);
    }
  };

  if (!isSuperadmin) {
    return null;
  }

  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem asChild>
            <NavLink
              className="cursor-pointer"
              to={`${AREAS_ADMIN_PATH}/${area.id}`}
            >
              Details
            </NavLink>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-pointer"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export const AreasView: React.FC<React.PropsWithChildren> = () => {
  const { isSuperadmin } = useAuth();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const query = useDebounce(q, 300);
  const setModal = useSetRecoilState(modalState);
  const { data: pareas, isLoading: isLoadingAreas } = useQuery(
    [FETCH_ADMIN_AREAS_QUERY, page, query],
    async () => {
      return fetchAdminAreas({ page, q: query });
    }
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <div className="space-y-4">
      <Header title="Areas">
        <div className="space-x-2 flex">
          <Input
            value={q}
            onChange={(ev) => setQ(ev.target.value)}
            placeholder="Search..."
            className="w-[300px]"
          />
          {isSuperadmin && (
            <Button
              onClick={() => setModal({ key: "AREA", component: AreaModal })}
            >
              Add
            </Button>
          )}
        </div>
      </Header>
      <STable
        columns={columns}
        data={pareas?.result}
        isLoading={isLoadingAreas}
      />
      {pareas && (
        <PaginationControlled
          currentPage={pareas.currentPage}
          totalPages={pareas.totalPages}
          onPageChange={setPage}
        />
      )}
    </div>
  );
};
