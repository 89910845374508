import { getRequiredValidator } from "common/validators";
import {
  FieldValues,
  UseFormRegister,
  FieldPath,
  FieldErrorsImpl,
} from "react-hook-form";

type NormalFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  placeholder: string;
  errors: Partial<FieldErrorsImpl>;
  noValidation?: boolean;
  asNumber?: boolean;
};

export const useNormalField = <T extends FieldValues>({
  name,
  placeholder,
  errors,
  register,
  noValidation = false,
  asNumber = false,
}: NormalFieldProps<T>) => {
  return {
    placeholder,
    errors,
    ...register(name, {
      ...(noValidation ? {} : getRequiredValidator()),
      ...(asNumber ? { valueAsNumber: true } : {}),
    }),
  };
};
