import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEn from "../translations/common/en.json";
import commonNl from "../translations/common/nl.json";
import { defaultLocale } from "./atoms";

const resources = {
  en: {
    common: commonEn,
  },
  nl: {
    common: commonNl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLocale,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
