import { addCompany, FETCH_ADMIN_COMPANIES_QUERY } from "admin/endpoints";
import { Company } from "admin/types";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { ModalProps } from "common/types";
import { Button } from "design-system/button";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "shad/components/ui/dialog";

interface FormProps extends Omit<Company, "id" | "integrations"> {}

const defaultValues: FormProps = {
  name: "",
};

export const CompanyModal: React.FC<ModalProps> = ({ handleClose }) => {
  const queryClient = useQueryClient();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => addCompany(data),
    {
      onSuccess: () => {
        successMessage("Done");
        handleClose();
        queryClient.invalidateQueries([FETCH_ADMIN_COMPANIES_QUERY]);
      },
    }
  );

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  return (
    <div>
      <DialogHeader>
        <DialogTitle>Add Company</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 mt-4 mb-8">
        <InputForward
          {...useNormalField({
            name: "name",
            register,
            errors,
            placeholder: "Company name",
          })}
        />
      </div>
      <DialogFooter>
        <Button
          isLoading={mutation.isLoading}
          disabled={!(isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          Add
        </Button>
      </DialogFooter>
    </div>
  );
};
