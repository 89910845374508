import { supxios } from "api";
import { Area, Discount, Location } from "common/types";
import { Profile } from "user/types";
import {
  AdminLocation,
  Booking,
  BookingPopulated,
  Station,
  StationLocker,
  PaginatedParams,
  PaginatedResponse,
  UserNotification,
  Company,
  Analytics,
  Monitoring,
  BookingWithLocation,
  FitogramLocation,
  Integration,
  AdminLocationWithIntegrations,
  FitogramEvent,
  FitogramBooking,
} from "./types";

export const addAdminLocation = async (location: Omit<AdminLocation, "id">) => {
  return (await supxios.post("/admin/locations", location)).data;
};

export const addAdminStationLocker = async (station: Omit<Station, "id">) => {
  return (await supxios.post("/admin/lockers", station)).data;
};

export const updateAdminStationLocker = async (
  id: string,
  locker: StationLocker
) => (await supxios.put(`/admin/lockers/${id}`, locker)).data;

export const addAdminStation = async (station: Omit<Station, "id">) => {
  return (await supxios.post("/admin/stations", station)).data;
};

export const updateAdminLocation = async (
  id: string,
  location: AdminLocation
) => {
  return (await supxios.put(`/admin/locations/${id}`, location)).data;
};

export const addArea = async (area: Omit<Area, "id">) => {
  const resp = await supxios.post("/admin/areas", area);

  return resp.data;
};

export const updateAdminStation = async (id: string, station: Station) =>
  (await supxios.put(`/admin/stations/${id}`, station)).data;

export const updateAdminArea = async (id: string, area: Area) => {
  return (await supxios.put(`/admin/areas/${id}`, area)).data;
};

export const FETCH_ADMIN_AREAS_QUERY = "FETCH_ADMIN_AREAS_QUERY";
export const fetchAdminAreas = async (
  params?: PaginatedParams
): Promise<PaginatedResponse<Area>> => {
  const mergeParams = {
    page: 1,
    limit: 10,
    ...params,
  };
  return (await supxios.get("/admin/areas", { params: mergeParams })).data;
};

export const FETCH_ADMIN_AREA_QUERY = "FETCH_ADMIN_AREA_QUERY";
export const fetchAdminArea = async (id: string) => {
  return (await supxios.get("/admin/areas/" + id)).data;
};

export const FETCH_ADMIN_PROFILES_QUERY = "FETCH_ADMIN_PROFILES_QUERY";
export const fetchAdminProfiles = async (
  params?: PaginatedParams
): Promise<PaginatedResponse<Profile>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };
  return (await supxios.get("/admin/profiles", { params: mergeParams })).data;
};

export const FETCH_ADMIN_PROFILE_QUERY = "FETCH_ADMIN_PROFILE_QUERY";
export const fetchAdminProfile = async (id: string) => {
  return (await supxios.get("/admin/profiles/" + id)).data;
};

export const addProfileToCompanyAdmins = async (
  id: string,
  companyId: string
) => {
  return (await supxios.put(`/admin/profiles/${id}/company/${companyId}/add`))
    .data;
};

export const removeProfilefromCompanyAdmins = async (id: string) => {
  return (await supxios.put(`/admin/profiles/${id}/company/remove`)).data;
};

export const FETCH_ADMIN_LOCATION_QUERY = "FETCH_ADMIN_LOCATION_QUERY";
export const fetchAdminLocation = async (
  id: string
): Promise<AdminLocationWithIntegrations> => {
  return (await supxios.get("/admin/locations/" + id)).data;
};

export const DELETE_FITOGRAM_EVENT_QUERY = "DELETE_FITOGRAM_EVENT_QUERY";
export const deleteFitogramEvent = async (
  id: string,
  providerId: string
): Promise<void> => {
  return (
    await supxios.delete(`/fitogram/events/${id}`, { params: { providerId } })
  ).data;
};

export const FETCH_FITOGRAM_LOCATIONS_QUERY = "FETCH_FITOGRAM_LOCATIONS_QUERY";
export const fetchFitogramLocations = async (
  providerId: string
): Promise<FitogramLocation[]> => {
  return (await supxios.get("/fitogram/locations/", { params: { providerId } }))
    .data;
};

export const CREATE_PUBLISH_FITOGRAM_DAY_EVENTS_QUERY =
  "CREATE_PUBLISH_FITOGRAM_DAY_EVENTS_QUERY";
export const createAndPublishFitogramDayEvents = async (data: {
  providerId: string;
  locationId: string;
  startDate: string;
}): Promise<FitogramEvent[]> => {
  return (await supxios.post("/fitogram/events", data)).data;
};

export const UPDATE_SEATS_FITOGRAM_DAY_EVENTS_QUERY =
  "UPDATE_SEATS_FITOGRAM_DAY_EVENTS_QUERY";
export const updateSeatsFitogramDayEvents = async (data: {
  providerId: string;
  locationId: string;
  startDate: string;
}): Promise<string[]> => {
  return (await supxios.post("/fitogram/events/seats", data)).data;
};

export const FETCH_FITOGRAM_EVENTS_QUERY = "FETCH_FITOGRAM_EVENTS_QUERY";
export const fetchFitogramEvents = async (params: {
  providerId: string;
  locationId: string;
  startDate: string;
}): Promise<FitogramEvent[]> => {
  return (await supxios.get("/fitogram/events", { params })).data;
};

export const FETCH_FITOGRAM_BOOKINGS_QUERY = "FETCH_FITOGRAM_BOOKINGS_QUERY";
export const fetchFitogramBookings = async (params: {
  providerId: string;
  eventId: string;
  take: "1000";
}): Promise<FitogramBooking[]> => {
  return (await supxios.get("/fitogram/bookings", { params })).data;
};

export const FETCH_ADMIN_AREAS_LOCATION_QUERY =
  "FETCH_ADMIN_AREAS_LOCATION_QUERY";
export const fetchAdminAreasLocation = async (id: string) => {
  return (await supxios.get(`/admin/areas/${id}/locations/`)).data;
};

export const FETCH_ADMIN_LOCATIONS_QUERY = "FETCH_ADMIN_LOCATIONS_QUERY";
export const fetchAdminLocations = async (
  params?: PaginatedParams
): Promise<PaginatedResponse<Location>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };
  return (await supxios.get("/admin/locations", { params: mergeParams })).data;
};

export const FETCH_ADMIN_STATIONS_QUERY = "FETCH_ADMIN_STATIONS_QUERY";
export const fetchAdminStations = async (
  params?: PaginatedParams
): Promise<PaginatedResponse<Location>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };
  return (await supxios.get("/admin/stations", { params: mergeParams })).data;
};

export const FETCH_ADMIN_LOCATION_STATIONS_QUERY =
  "FETCH_ADMIN_LOCATION_STATIONS_QUERY";
export const fetchAdminLocationStations = async (locationId: string) => {
  return (await supxios.get(`/admin/locations/${locationId}/stations`)).data;
};

export const FETCH_ADMIN_BOOKINGS_OVERVIEW_QUERY =
  "FETCH_ADMIN_BOOKINGS_OVERVIEW_QUERY";
export const fetchAdminBookingsOverview = async (
  date: string,
  locationId?: string
): Promise<BookingWithLocation[]> => {
  const params = new URLSearchParams({
    date,
  });

  if (locationId) {
    params.append("locationId", locationId);
  }
  return (await supxios.get(`/admin/bookings/overview`, { params })).data;
};

export const FETCH_ADMIN_STATION_DETAIL_QUERY =
  "FETCH_ADMIN_STATION_DETAIL_QUERY";
export const fetchAdminStation = async (id: string) => {
  return (await supxios.get(`/admin/stations/${id}`)).data;
};

export const FETCH_ADMIN_BOOKINGS_QUERY = "FETCH_ADMIN_BOOKINGS_QUERY";
export const fetchAdminBookings = async (
  params?: PaginatedParams
): Promise<PaginatedResponse<Booking>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };
  return (await supxios.get("/admin/bookings", { params: mergeParams })).data;
};

export const FETCH_ADMIN_USER_BOOKINGS_QUERY =
  "FETCH_ADMIN_USER_BOOKINGS_QUERY";
export const fetchAdminProfileBookings = async (
  profileId: string,
  params?: PaginatedParams
): Promise<PaginatedResponse<Booking>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };
  return (
    await supxios.get(`/admin/profiles/${profileId}/bookings`, {
      params: mergeParams,
    })
  ).data;
};

export const FETCH_ADMIN_USER_NOTIFICATIONS_QUERY =
  "FETCH_ADMIN_USER_NOTIFICATIONS_QUERY";
export const fetchAdminUserNotifications = async (
  profileId: string,
  params?: PaginatedParams
): Promise<PaginatedResponse<UserNotification>> => {
  const mergeParams = {
    limit: 10,
    page: 1,
    ...params,
  };

  return (
    await supxios.get(`/admin/profiles/${profileId}/notifications`, {
      params: mergeParams,
    })
  ).data;
};

export const FETCH_UPCOMING_BOOKINGS_QUERY = "FETCH_UPCOMING_BOOKINGS_QUERY";
export const fetchUpcomingBookings = async (): Promise<Booking[]> => {
  return (await supxios.get("/bookings/upcoming/list")).data;
};

// Not there yet
// export const CREATE_ADMIN_BOOKING_QUERY = "CREATE_ADMIN_BOOKING_QUERY";
// export const createAdminBooking = async (bookingData: Partial<Booking>) => {
//   return (await supxios.post('/admin/bookings', bookingData)).data;
// };

export const FETCH_ADMIN_BOOKING_QUERY = "FETCH_ADMIN_BOOKING_QUERY";
export const fetchAdminBooking = async (id: string) => {
  return (await supxios.get(`/admin/bookings/${id}`)).data;
};

// Not there yet
// export const UPDATE_ADMIN_BOOKING_QUERY = "UPDATE_ADMIN_BOOKING_QUERY";
// export const updateAdminBooking = async (bookingData: Partial<Booking>) => {
//   return (await supxios.put('/admin/bookings', bookingData)).data;
// };

export const DELETE_ADMIN_BOOKING_QUERY = "DELETE_ADMIN_BOOKING_QUERY";
export const deleteAdminBooking = async (id: string) => {
  return (await supxios.delete("/admin/bookings/" + id)).data;
};

export const FETCH_ADMIN_DISCOUNTS_QUERY = "FETCH_ADMIN_DISCOUNTS_QUERY";
export const fetchAdminDiscounts = async () => {
  return (await supxios.get("/admin/discounts")).data;
};

export const FETCH_ADMIN_DISCOUNT_USAGE_COUNT_QUERY =
  "FETCH_ADMIN_DISCOUNT_USAGE_COUNT_QUERY";
export const fetchAdminDiscountUsageCount = async (code: string) => {
  return (await supxios.get(`/admin/discounts/${code}/count`)).data;
};

export const FETCH_ADMIN_COMPANIES_QUERY = "FETCH_ADMIN_COMPANIES_QUERY";
export const fetchAdminCompanies = async (): Promise<Company[]> => {
  return (await supxios.get("/admin/companies")).data;
};

export const TOGGLE_ADMIN_COMPANIES_INTEGRATION_QUERY =
  "TOGGLE_ADMIN_COMPANIES_INTEGRATION_QUERY";
export const toggleAdminCompaniesIntegration = async (data: {
  companyId: string;
  provider: Integration["provider"];
}): Promise<Integration> => {
  return (await supxios.put("/admin/companies/integrations", data)).data;
};

export const FETCH_ADMIN_MONITORING_QUERY = "FETCH_ADMIN_MONITORING_QUERY";
export const fetchAdminMonitoring = async (): Promise<Monitoring> => {
  return (await supxios.get("/admin/monitoring")).data;
};

export const addCompany = async (company: Omit<Company, "id">) => {
  return (await supxios.post("/admin/companies", company)).data;
};

export const FETCH_ADMIN_ANALYTICS_QUERY = "FETCH_ADMIN_ANALYTICS_QUERY";
export const fetchAdminAnalytics = async (data: {
  startLocalDateTime: string;
  endLocalDateTime: string;
  locationId?: string;
}): Promise<Analytics> => {
  return (await supxios.post("/admin/analytics", data)).data;
};

export const DELETE_ADMIN_DISCOUNT_QUERY = "DELETE_ADMIN_DISCOUNT_QUERY";
export const deleteAdminDiscount = async (id: string) => {
  return (await supxios.delete("/admin/discounts/" + id)).data;
};

export const deleteAdminLocation = async (id: string) => {
  return (await supxios.delete("/admin/locations/" + id)).data;
};

export const deleteAdminStation = async (id: string) => {
  return (await supxios.delete("/admin/stations/" + id)).data;
};

export const deleteAdminLocker = async (id: string) => {
  return (await supxios.delete("/admin/lockers/" + id)).data;
};

export const deleteAdminArea = async (id: string) => {
  return (await supxios.delete("/admin/areas/" + id)).data;
};

export const addDiscount = async (discount: Omit<Discount, "id">) => {
  return (await supxios.post("/admin/discounts", discount)).data;
};

export const updateDiscount = async (discount: Discount) => {
  return (await supxios.put(`/admin/discounts/${discount.id}`, discount)).data;
};

export const openAdminLocker = async (lockerId: string) => {
  return (await supxios.post(`/admin/lockers/${lockerId}/open`)).data;
};

export const readAdminLocker = async (lockerId: string) => {
  return (await supxios.get(`/admin/lockers/${lockerId}/read`)).data;
};

export const updateAdminStationLockers = async (stationId: string) => {
  return (await supxios.get(`/admin/stations/${stationId}/lockers/status`))
    .data;
};

export const resendAdminBookingInvoice = async (
  booking: BookingPopulated
): Promise<Booking[]> => {
  return (await supxios.post(`/admin/bookings/${booking.id}/invoice`, booking))
    .data;
};
