import { Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchAdminStations,
  FETCH_ADMIN_STATIONS_QUERY,
  deleteAdminStation,
} from "admin/endpoints";
import { createColumnHelper } from "@tanstack/react-table";
import { Station } from "admin/types";
import { STable } from "design-system/s-table";
import { STATIONS_ADMIN_PATH } from "admin/routes";
import { ConfirmationButton } from "common/components/confirmation-button";
import { DotsThree } from "phosphor-react";
import { Link } from "design-system/links";
import { queryClient } from "index";
import { Header } from "admin/components/header";
import { Button } from "design-system/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";
import { useEffect, useState } from "react";
import { useDebounce } from "common/hooks/use-debounce";
import { Input } from "shad/components/ui/input";
import { PaginationControlled } from "admin/components/pagination-controlled";

export const StationsView: React.FC<React.PropsWithChildren> = () => {
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const query = useDebounce(q, 300);
  const { data: stations, isLoading } = useQuery(
    [FETCH_ADMIN_STATIONS_QUERY, page, query],
    () => {
      return fetchAdminStations({ page, q: query });
    }
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <div className="space-y-4">
      <Header title="Stations">
        <Input
          value={q}
          onChange={(ev) => setQ(ev.target.value)}
          placeholder="Search..."
          className="w-[300px]"
        />
      </Header>
      <STable isLoading={isLoading} columns={columns} data={stations?.result} />
      {stations && (
        <PaginationControlled
          currentPage={stations.currentPage}
          totalPages={stations.totalPages}
          onPageChange={setPage}
        />
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<Station>();
const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => (
      <Link to={`${STATIONS_ADMIN_PATH}/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor(() => "address", {
    id: "address",
    header: () => <strong>Address</strong>,
    cell: (info) => {
      return (
        <Box>
          {info.row.original.ip}:{info.row.original.port}
        </Box>
      );
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => <div />,
    cell: (info) => {
      return <RowActions station={info.row.original} />;
    },
  }),
];

const RowActions = ({ station }: { station: Station }) => {
  const onClick = async () => {
    try {
      await deleteAdminStation(station.id);
      queryClient.invalidateQueries(FETCH_ADMIN_STATIONS_QUERY);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem asChild>
            <NavLink
              className="cursor-pointer"
              to={`${STATIONS_ADMIN_PATH}/${station.id}`}
            >
              Details
            </NavLink>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-pointer"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
