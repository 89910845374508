import {
  fetchAdminProfiles,
  FETCH_ADMIN_PROFILES_QUERY,
} from "admin/endpoints";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { Profile } from "user/types";
import { STable } from "design-system/s-table";
import { USERS_ADMIN_PATH } from "admin/routes";
import { PaginationControlled } from "admin/components/pagination-controlled";
import { useDebounce } from "common/hooks/use-debounce";
import { Input } from "shad/components/ui/input";
import { Header } from "admin/components/header";
import { NavLink } from "react-router-dom";
import { delayQuery } from "common/utils";

const delayProfiles = delayQuery<ReturnType<typeof fetchAdminProfiles>>();

export const UsersView: React.FC<React.PropsWithChildren> = () => {
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const query = useDebounce(q, 300);
  const { data, isFetching } = useQuery(
    [FETCH_ADMIN_PROFILES_QUERY, page, query],
    async () => delayProfiles(() => fetchAdminProfiles({ page, q: query }))
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <div className="space-y-4">
      <Header title="Users">
        <Input
          value={q}
          onChange={(ev) => setQ(ev.target.value)}
          placeholder="Search..."
          className="w-[300px]"
        />
      </Header>
      <STable
        columns={getColumns()}
        data={data?.result}
        isLoading={isFetching}
      />
      {data && (
        <PaginationControlled
          currentPage={data?.currentPage}
          totalPages={data?.totalPages}
          onPageChange={setPage}
        />
      )}
    </div>
  );
};

const getColumns = () => {
  const columnHelper = createColumnHelper<Profile>();
  const columns = [
    columnHelper.accessor("firstName", {
      header: () => <strong>First name</strong>,
      cell: (info) => {
        return (
          <NavLink
            className="underline"
            to={`${USERS_ADMIN_PATH}/${info.row.original?.id}`}
          >
            {info.getValue()}
          </NavLink>
        );
      },
    }),
    columnHelper.accessor("lastName", {
      header: () => <strong>Last name</strong>,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor("email", {
      header: () => <strong>Email</strong>,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.display({
      id: "address",
      header: () => <strong>Street</strong>,
      cell: (info) => {
        return (
          <div className="space-y-1">
            <div>{info.row.original.street}</div>
            <div>{info.row.original.postcode}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("phoneNumber", {
      header: () => <strong>Phone Number</strong>,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
  ];

  return columns;
};
