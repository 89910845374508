import { Header } from "admin/components/header";
import {
  fetchAdminArea,
  FETCH_ADMIN_AREA_QUERY,
  updateAdminArea,
} from "admin/endpoints";
import { AREAS_ADMIN_PATH } from "admin/routes";
import { InputForward } from "common/components/input-forward";
import { useNormalField } from "common/hooks/use-normal-field";
import { useSnack } from "common/hooks/use-snack";
import { Area } from "common/types";
import { Button } from "design-system/button";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import {
  CardContent,
  CardHeader,
  CardTitle,
  Card,
} from "shad/components/ui/card";

interface FormProps extends Omit<Area, "id"> {}

const defaultValues: FormProps = {
  name: "",
  timezone: "",
};

export const AreaEditForm: React.FC = () => {
  const { id } = useParams();
  const { successMessage } = useSnack();
  const { data: area } = useQuery([FETCH_ADMIN_AREA_QUERY, id], () => {
    if (id) {
      return fetchAdminArea(id);
    }
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues,
  });

  const mutation = useMutation<any, any, any, any>(
    async (data) => {
      return updateAdminArea(data.id, data);
    },
    {
      onSuccess: () => {
        successMessage("Done!");
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutation.mutate(data);
  };

  useEffect(() => {
    if (area) {
      reset(area);
    }
  }, [area, reset]);

  return (
    <div className="space-y-4">
      <Header
        title={area?.name}
        subtitle={
          <div className="flex gap-2 text-gray-500 text-sm">
            <NavLink className="underline" to={AREAS_ADMIN_PATH}>
              Areas
            </NavLink>
          </div>
        }
      />
      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Update area</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4 mt-2">
            <div>
              <InputForward
                {...useNormalField({
                  name: "name",
                  errors,
                  register,
                  placeholder: "Area name",
                })}
              />
            </div>
            <div>
              <Button
                className="self-end"
                isLoading={mutation.isLoading}
                disabled={!(isValid && isDirty)}
                onClick={handleSubmit(onSubmit)}
              >
                Update
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
