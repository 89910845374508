import { Booking } from "admin/types";
import { DecodedToken } from "auth/types";
import { BASE_STORAGE } from "design-system/constants";
import { jwtDecode } from "jwt-decode";
import { BookingStateAtom } from "user/atoms";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export const formatValue = (
  value: number,
  currency = "EUR",
  locale = "nl-NL"
) => {
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    value
  );
};

export const delayQuery = <T>(delay = 500) => {
  let timeout: number;
  return (callback: () => T): Promise<T> =>
    new Promise((resolve) => {
      if (timeout) clearInterval(timeout);
      timeout = window.setTimeout(async () => {
        const response = await callback();

        resolve(response);
      }, delay);
    });
};

export const setInStorage = (
  key: string,
  value: any,
  storage = window.localStorage
) => {
  storage.setItem(BASE_STORAGE + key, value);
};

export const getFromStorage = (key: string, storage = window.localStorage) => {
  return storage.getItem(BASE_STORAGE + key);
};

export const removeFromStorage = (
  key: string,
  storage = window.localStorage
) => {
  storage.removeItem(BASE_STORAGE + key);
};

export const fakeRequest = <T>(
  data: T,
  delay = 2000,
  hasError = false
): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (hasError) {
        reject(new Error("Rejected"));
      } else {
        resolve(data);
      }
    }, delay);
  });
};

export const _get = (object: any, path: any) => {
  if (typeof path === "string")
    path = path.split(".").filter((key) => key.length);
  return path.reduce((dive: any, key: any) => dive && dive[key], object);
};

export const bookingConverter = (
  booking: BookingStateAtom,
  profileId?: string
): Partial<Booking> => {
  const boards = booking.boardsNumber || 1;
  const locationId = booking.locationId || "";
  const areaId = booking.areaId || "";
  const duration = booking.duration || 1;
  const startAtTime = booking.time || 0;
  const endAtTime = startAtTime + duration;

  return {
    boards,
    status: booking.status,
    locationId,
    profileId,
    areaId,
    dateString: booking.date!,
    startHour: startAtTime,
    endHour: endAtTime,
    discount: booking.discount,
  };
};

export const TOKEN_STORAGE = "token";
export const getToken = (): string | null => getFromStorage(TOKEN_STORAGE);
export const getDecodedToken = (): DecodedToken | null => {
  const token = getToken();

  return token ? jwtDecode(token) : null;
};

export const convertNumberToTime = (num: number) => {
  // Convert the number to an integer
  const hours = Math.floor(num);

  // Calculate the minutes as the decimal part of the number multiplied by 60
  const minutes = Math.round((num - hours) * 60);

  // Return the time string in the format "HH:MM"
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

// Create an instance of Notyf
const toast = new Notyf();

export { toast };
