import { superadminRoutes, businessRoutes } from "admin/routes";
import { commonRoutes } from "common/routes";
import { Route } from "common/types";
import { userRoutes } from "user/routes";
import { authRoutes } from "auth/routes";

export const HOME_PATH = "/";

export const getSideMenuItems = (isSuperadmin: boolean): Route[] => {
  const items = [...businessRoutes];

  if (isSuperadmin) {
    items.splice(4, 0, ...superadminRoutes);
  }

  return items;
};

export const getRoutes = (isSuperadmin: boolean): Route[] => {
  const routes = [
    ...userRoutes,
    ...businessRoutes,
    ...authRoutes,
    ...commonRoutes,
  ];

  if (isSuperadmin) {
    routes.push(...superadminRoutes);
  }

  return routes;
};
