import { Spinner } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isProfileLoading } = useAuth();

  return isProfileLoading ? (
    <div>
      <Spinner color="white" />
    </div>
  ) : (
    <>{children}</>
  );
};
