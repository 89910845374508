import { AreaEditForm } from "admin/forms/area-edit";
import { AreaLocationsView } from "admin/views/locations/area-locations";
import { useAuth } from "auth/hooks/use-auth";

export const AreaDetails: React.FC = () => {
  const { isSuperadmin } = useAuth();
  return (
    <div className="space-y-12">
      {isSuperadmin && <AreaEditForm />}
      <AreaLocationsView />
    </div>
  );
};
