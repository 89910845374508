import {
  FETCH_ADMIN_MONITORING_QUERY,
  fetchAdminMonitoring,
} from "admin/endpoints";

import React from "react";
import { useQuery } from "react-query";
import { Header } from "admin/components/header";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader } from "shad/components/ui/card";
import { StationLockerPopulated } from "admin/types";

const linkClasses =
  "p-2 odd:bg-white even:bg-[#f2f2f2] block hover:bg-[#f3e8ff] underline text-xs";

const groupLockersByLocation = (inactiveLockers: StationLockerPopulated[]) => {
  const lockers = inactiveLockers.reduce(
    (acc, locker) => {
      const locationId = locker.location.id;
      if (!acc[locationId]) {
        acc[locationId] = [];
      }
      acc[locationId].push(locker);
      return acc;
    },
    {} as Record<string, StationLockerPopulated[]>
  );

  return Object.values(lockers);
};

export const MonitoringView: React.FC = () => {
  const { data, isFetching } = useQuery([FETCH_ADMIN_MONITORING_QUERY], () => {
    return fetchAdminMonitoring();
  });

  return (
    <div className="space-y-4">
      <Header title="Monitoring" />
      {isFetching || !data ? (
        <>Loading...</>
      ) : (
        <div className="space-y-4">
          <Card>
            <CardHeader>
              <div className="font-bold">Currently open lockers</div>
              <div>
                <small>A list of the currently open lockers</small>
              </div>
            </CardHeader>
            <CardContent>
              {!!data.openLockers.length ? (
                data.openLockers.map((locker) => {
                  return (
                    <Link
                      to={`/stations/${locker.stationId}`}
                      key={locker.id}
                      className={linkClasses}
                    >
                      <div>
                        {locker.name} | {locker.station.name}
                      </div>
                    </Link>
                  );
                })
              ) : (
                <>---</>
              )}
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <div className="font-bold">Inactive lockers</div>
              <div>
                <small>These lockers will not be able to be booked</small>
              </div>
            </CardHeader>
            <CardContent>
              {!!data.inactiveLockers.length ? (
                groupLockersByLocation(data.inactiveLockers).map(
                  (locationLockers, index) => (
                    <div key={index} className="mt-2 first:mt-0">
                      <div className="font-bold text-sm mb-2">
                        {locationLockers[0].location?.name}
                      </div>
                      {locationLockers.map((locker) => (
                        <div key={locker.id} className={linkClasses}>
                          <Link to={`/stations/${locker.stationId}`}>
                            <div>
                              {locker.station.name} | {locker.name}
                            </div>
                            {locker.description && (
                              <div className="text-xs italic text-gray-500">
                                {locker.description}
                              </div>
                            )}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )
                )
              ) : (
                <>---</>
              )}
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <div className="font-bold">Inactive locations</div>
              <div>
                <small>These locations will not be shown in the app</small>
              </div>
            </CardHeader>
            <CardContent>
              {!!data.inactiveLocations.length ? (
                data.inactiveLocations.map((location) => {
                  return (
                    <Link
                      to={`/location/${location.id}`}
                      key={location.id}
                      className={linkClasses}
                    >
                      <div>{location.name}</div>
                    </Link>
                  );
                })
              ) : (
                <>---</>
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};
