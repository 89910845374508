import { atom } from "recoil";
import { AdminArea, AdminLocation, Station } from "./types";

export const locationsAdminState = atom<AdminLocation[] | null>({
  key: "locationsAdminState",
  default: null,
});

export const areasAdminState = atom<AdminArea[] | null>({
  key: "areasAdminState",
  default: null,
});

export const stationsAdminState = atom<Station[] | null>({
  key: "stationsAdminState",
  default: null,
});
