import { Box } from "@chakra-ui/react";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchAdminLocationStations,
  FETCH_ADMIN_LOCATION_STATIONS_QUERY,
  deleteAdminStation,
} from "admin/endpoints";
import { createColumnHelper } from "@tanstack/react-table";
import { Station } from "admin/types";
import { STable } from "design-system/s-table";
import { STATIONS_ADMIN_PATH } from "admin/routes";
import { useSetRecoilState } from "recoil";
import { modalState } from "common/atoms";
import { StationModal } from "admin/modals/station";
import { ConfirmationButton } from "common/components/confirmation-button";
import { DotsThree } from "phosphor-react";
import { Link } from "design-system/links";
import { queryClient } from "index";
import { Header } from "admin/components/header";
import { Button } from "design-system/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "shad/components/ui/dropdown-menu";

export const LocationStationsView: React.FC<React.PropsWithChildren> = () => {
  const setModal = useSetRecoilState(modalState);
  const { id } = useParams();
  const { data: stations, isLoading } = useQuery(
    [FETCH_ADMIN_LOCATION_STATIONS_QUERY, id],
    () => {
      if (id) {
        return fetchAdminLocationStations(id);
      }

      return null;
    }
  );

  return (
    <div className="space-y-4">
      <Header title="Stations">
        <Button
          onClick={() =>
            setModal({
              key: "STATION",
              component: StationModal,
              props: { locationId: id },
            })
          }
        >
          Add
        </Button>
      </Header>
      <div className="space-y-4">
        <STable columns={columns} data={stations} isLoading={isLoading} />
      </div>
    </div>
  );
};

const columnHelper = createColumnHelper<Station>();
const columns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => (
      <Link to={`${STATIONS_ADMIN_PATH}/${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor(() => "address", {
    id: "address",
    header: () => <strong>Address</strong>,
    cell: (info) => {
      return (
        <Box>
          {info.row.original.ip}:{info.row.original.port}
        </Box>
      );
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => <div />,
    cell: (info) => {
      return <RowActions station={info.row.original} />;
    },
  }),
];

const RowActions = ({ station }: { station: Station }) => {
  const onClick = async () => {
    try {
      await deleteAdminStation(station.id);
      queryClient.invalidateQueries(FETCH_ADMIN_LOCATION_STATIONS_QUERY);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="data-[state=open]:bg-muted"
          >
            <DotsThree size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem asChild>
            <NavLink
              className="cursor-pointer"
              to={`${STATIONS_ADMIN_PATH}/${station.id}`}
            >
              Details
            </NavLink>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <ConfirmationButton
              size="icon"
              variant="ghost"
              className="block w-full text-left font-normal rounded-sm px-2 py-1.5 text-sm outline-none h-auto cursor-pointer"
              onConfirm={onClick}
            >
              Delete
            </ConfirmationButton>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
