import { Stack } from "@chakra-ui/react";
import {
  FETCH_ADMIN_ANALYTICS_QUERY,
  fetchAdminAnalytics,
} from "admin/endpoints";
import { Analytics, AnalyticsItem } from "admin/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shad/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "shad/components/ui/popover";
import { useState } from "react";
import { useQuery } from "react-query";
import { cn } from "shad/lib/utils";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";
import { Calendar } from "shad/components/ui/calendar";
import { Button } from "shad/components/ui/button";
import { dateToLocal, dateToString, formatMoney } from "./utils";
import { AnalyticsCard } from "./analytics-card";
import { Spinner } from "phosphor-react";

const initialDates = {
  from: new Date(),
  to: new Date(),
};

const initalAnalyticsItem: Omit<AnalyticsItem, "id" | "name"> = {
  bookingsWithDiscount: 0,
  revenueWithoutVat: 0,
  revenueWithVat: 0,
  totalBookings: 0,
  totalPaidBookings: 0,
  vat: 0,
};

export const HomeView: React.FC<React.PropsWithChildren> = () => {
  const [date, setDate] = useState<DateRange | undefined>(initialDates);
  const [locationId, setLocationId] = useState("");
  const { data: analytics, isFetching } = useQuery(
    [FETCH_ADMIN_ANALYTICS_QUERY, date?.from, date?.to],
    () => {
      if (date?.from && date?.to) {
        const params: {
          startLocalDateTime: string;
          endLocalDateTime: string;
        } = {
          startLocalDateTime: dateToLocal(date.from, "00:01"),
          endLocalDateTime: dateToLocal(date.to, "23:59"),
        };

        return fetchAdminAnalytics(params);
      }
    },
    {
      enabled: !!(date?.from && date.to),
    }
  );

  const dropdownLocations = (analytics?.dataByLocations || [])?.map(
    (locData) => ({
      label: locData.name,
      value: locData.id,
    })
  );

  const onTodayClick = () => {
    setDate(initialDates);
  };

  const onChangeLocation = (value: string) => {
    setLocationId(value);
  };

  const getTotalData = () => {
    if (locationId && locationId !== "ALL") {
      return analytics?.dataByLocations.find((loc) => loc.id === locationId);
    }

    return analytics?.dataByLocations.reduce(
      (acc, curr) => {
        acc.bookingsWithDiscount =
          acc.bookingsWithDiscount + curr.bookingsWithDiscount;
        acc.revenueWithVat = acc.revenueWithVat + curr.revenueWithVat;
        acc.revenueWithoutVat = acc.revenueWithoutVat + curr.revenueWithoutVat;
        acc.totalBookings = acc.totalBookings + curr.totalBookings;
        acc.totalPaidBookings = acc.totalPaidBookings + curr.totalPaidBookings;
        acc.vat = acc.vat + curr.vat;

        return acc;
      },
      { ...initalAnalyticsItem }
    );
  };

  const totalData = getTotalData();

  const onExport = (
    analytics: Analytics | undefined,
    locationName?: string
  ) => {
    const title = `From: ${dateToString(date!.from!)} To: ${dateToString(
      date!.to!
    )}\n\n`;
    const utf = "data:text/csv;charset=utf-8,";
    const csvHeaders =
      title +
      "Name,Bookings With Discount,Total Bookings,Total Paid Bookings,VAT,Revenue Without VAT,Revenue With VAT\n";
    let csvContent: string = csvHeaders;

    analytics?.dataByLocations.forEach((data) => {
      csvContent +=
        [
          data.name,
          data.bookingsWithDiscount,
          data.totalBookings,
          data.totalPaidBookings,
          formatMoney(data.vat, {}, true),
          formatMoney(data.revenueWithoutVat, {}, true),
          formatMoney(data.revenueWithVat, {}, true),
        ].join(",") + "\n";
    });

    // csvContent += "\n";
    // csvContent += "\n";
    // csvContent += `From: ${dateToLocal(date!.from!, "00:01")}\n`;
    // csvContent += `To: ${dateToLocal(date!.to!, "23:59")}`;

    // let csvContent =
    //   "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(utf + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${analytics?.companyName} ${locationName || "-"} Analytics.csv`
    );
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold tracking-tight">Dashboard</h2>
        <div className="flex gap-2">
          <Button variant="outline" onClick={onTodayClick}>
            Today
          </Button>
          <Stack direction="row" alignItems="center">
            <div className={cn("grid gap-2", "")}>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant="outline"
                    className={cn(
                      "w-[300px] justify-start text-left font-normal",
                      !date && "text-muted-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                      date.to ? (
                        <>
                          {format(date.from, "LLL dd, y")} -{" "}
                          {format(date.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(date.from, "LLL dd, y")
                      )
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </Stack>
          <Select value={locationId} onValueChange={onChangeLocation}>
            <SelectTrigger>
              <SelectValue placeholder="Select location" />
            </SelectTrigger>
            <SelectContent>
              {[
                { label: "All locations", value: "ALL" },
                ...dropdownLocations,
              ].map((option, index: number) => (
                <SelectItem key={`option-${index}`} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Button
            onClick={() =>
              onExport(
                analytics,
                dropdownLocations.find((loc) => loc.value === locationId)?.label
              )
            }
          >
            Export
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <AnalyticsCard
          title="Total Bookings"
          content={
            <div className="text-xl">
              {isFetching ? <Spinner /> : totalData?.totalBookings}
            </div>
          }
        />

        <AnalyticsCard
          title="Total Paid Bookings"
          content={
            <div className="text-xl">
              {isFetching ? <Spinner /> : totalData?.totalPaidBookings}
            </div>
          }
        />

        <AnalyticsCard
          title="Bookings with discounts"
          content={
            <div className="text-xl">
              {isFetching ? <Spinner /> : totalData?.bookingsWithDiscount}
            </div>
          }
        />

        <AnalyticsCard
          title="VAT"
          content={
            <div className="text-xl">
              {isFetching ? <Spinner /> : formatMoney(totalData?.vat)}
            </div>
          }
        />

        <AnalyticsCard
          title="Revenue without VAT"
          content={
            <div className="text-xl">
              {isFetching ? (
                <Spinner />
              ) : (
                formatMoney(totalData?.revenueWithoutVat)
              )}
            </div>
          }
        />

        <AnalyticsCard
          title="Revenue with VAT"
          content={
            <div className="text-xl">
              {isFetching ? (
                <Spinner />
              ) : (
                formatMoney(totalData?.revenueWithVat)
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};
