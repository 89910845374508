import { BookingStatus } from "./types";

export const getBGColor = (status: string) => {
  if (status === "PAID") return "#00ff8d";
  if (status === "PENDING") return "#ffdb76";
  if (status === "UNPAID") return "#3b82f6";
  return "#ff6969";
};

export const getBadgeVariant = (status?: BookingStatus) => {
  if (status === "PAID") return "success";
  if (status === "PENDING") return "warning";
  if (status === "UNPAID") return "default";
  if (status === "FAILED") return "danger";
  return "default";
};
