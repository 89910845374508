import { useClipboard } from "@chakra-ui/react";
import { useSnack } from "common/hooks/use-snack";
import { Button } from "design-system/button";
import { Copy } from "phosphor-react";
import { useEffect } from "react";

export const CopyClipboard = ({ value }: { value: string }) => {
  const { onCopy, hasCopied } = useClipboard(value);
  const { successMessage } = useSnack();

  useEffect(() => {
    if (hasCopied) {
      successMessage("Copied");
    }
  }, [hasCopied, successMessage]);

  return (
    <Button variant="outline" onClick={onCopy}>
      <Copy />
    </Button>
  );
};
