import { toast } from "common/utils";
import { useCallback } from "react";

const duration = 2000;

export const useSnack = () => {
  const successMessage = useCallback(
    (message: string) =>
      toast.success({
        message,
        duration,
      }),
    []
  );
  const errorMessage = useCallback(
    (message: string) =>
      toast.error({
        message,
        duration,
      }),
    []
  );
  const infoMessage = useCallback(
    (message: string) =>
      toast.open({ message, duration, type: "info", className: "text-black" }),
    []
  );

  return {
    successMessage,
    errorMessage,
    infoMessage,
  };
};
