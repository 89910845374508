import { createColumnHelper } from "@tanstack/react-table";
import {
  FETCH_ADMIN_COMPANIES_QUERY,
  TOGGLE_ADMIN_COMPANIES_INTEGRATION_QUERY,
  fetchAdminCompanies,
  toggleAdminCompaniesIntegration,
} from "admin/endpoints";

import { CompanyPopulated, Integration } from "admin/types";
import { STable } from "design-system/s-table";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Header } from "admin/components/header";
import { Button } from "design-system/button";
import { useSetRecoilState } from "recoil";
import { modalState } from "common/atoms";
import { CompanyModal } from "admin/modals/company";
import clsx from "clsx";
import { toast } from "common/utils";

export const CompaniesView: React.FC = () => {
  const { data, isFetching } = useQuery([FETCH_ADMIN_COMPANIES_QUERY], () => {
    return fetchAdminCompanies();
  });
  const setModal = useSetRecoilState(modalState);

  return (
    <div className="space-y-4">
      <Header title="Companies">
        <Button
          className="self-end"
          onClick={() =>
            setModal({
              key: "DISCOUNT",
              component: CompanyModal,
              props: {},
            })
          }
        >
          Add
        </Button>
      </Header>
      <STable columns={bookingsColumns} data={data} isLoading={isFetching} />
    </div>
  );
};

const columnHelper = createColumnHelper<CompanyPopulated>();
export const bookingsColumns = [
  columnHelper.accessor("id", {
    cell: (info) => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor("name", {
    header: () => <strong>Name</strong>,
    cell: (info) => {
      const name = info.getValue();

      return <div>{name}</div>;
    },
  }),
  columnHelper.display({
    id: "integrations",
    header: () => <strong>Integrations</strong>,
    cell: (info) => {
      const integrations = info.row.original.integrations;

      if (!integrations?.length) {
        return "---";
      }

      return integrations.map((integration) => (
        <div key={integration.provider} className="flex items-center gap-4">
          <div>{integration.provider}</div>
          <IntegrationSwitch integration={integration} />
        </div>
      ));
    },
  }),
];

const IntegrationSwitch = ({
  integration: { provider, companyId, enabled },
}: {
  integration: Integration;
}) => {
  const { mutateAsync } = useMutation(
    [TOGGLE_ADMIN_COMPANIES_INTEGRATION_QUERY],
    () => {
      return toggleAdminCompaniesIntegration({
        provider,
        companyId,
      });
    }
  );
  const [_isChecked, setIsChecked] = useState(enabled);

  const onClick = async () => {
    try {
      const updatedIntegration = await mutateAsync();
      setIsChecked(updatedIntegration.enabled);
      toast.success({ message: "Done" });
    } catch (e) {
      toast.error({ message: "Error" });
    }
  };

  return (
    <button
      className="rounded-full w-10 h-5 bg-gray-200 flex items-center relative"
      onClick={onClick}
    >
      <div
        className={clsx(
          _isChecked ? "bg-primary tran left-6" : "bg-gray-500 left-1",
          "rounded-full w-3 h-3 absolute transition-all duration-300"
        )}
      />
    </button>
  );
};
